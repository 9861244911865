import React from 'react'
import styled from 'styled-components/macro'
import defaultToy from './../../assets/defaultToy.png'
import defaultAvatar from './../../assets/cardPhoto.png'
import axios from '../../utils/axios'
import { Link, useParams } from 'react-router-dom'
import { selectIsAuth } from '../../redux/slices/auth'
import CardPreview from '../Card/card-preview';
import { useSelector } from 'react-redux'


const Card = styled.section`
max-width: 1200px;
display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr;
grid-gap: 40px;
justify-content: center;
margin: 0 auto 30px;
 
@media (max-width: 577px){
  width: 90%;
  grid-template-columns: 50% 50%; 
  grid-gap: 10px;
  padding: 0 10px;
  margin: 0 auto;
    padding-top: 26px;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 15px;
    grid-template-rows: 269px;
    grid-auto-rows: 269px;
  & > a {
      overflow: hidden;
    }
}
`


const CardBlock = ({ id }) => {
    const [cards, setCards] = React.useState([])
    const isAuth = useSelector(selectIsAuth)
    const params = useParams()
    const userId = id || params.id;

    const fetchMyCards = async () => {
        try {
          if (userId) {
            const { data } = await axios.get(`/market/cards/${id || params.id}`)
            setCards(data)
          }
        } catch (error) {
            console.log(error)
        }
    }

    React.useEffect(() => {
        fetchMyCards()
    }, [])

  if (!cards){
      return(
      <div>Загрузка</div>)
    }  


  return (
    <Card>
        {cards?.filter((card) => !!card).sort((a,b) =>  b.createdAt.localeCompare(a.createdAt)).map((card, index) => (
            <CardPreview
              altWork={card.name} 
              imageWork={card.imgUrl ?`https://toobears.com/api/${card.imgUrl[0]}` : defaultToy}
              avatar={`https://toobears.com/api/${card.author.avatarUrl}` || defaultAvatar}
              shopName={card.author.shopname || `${card.author.username} ${card.author.surname}`}
              nameWork={card.name}
              price={card.price + ' ' + card.currency}
              size={card.size + 'cm'}
              likeActive={card.like.includes(isAuth && isAuth._id)}    
              status={card.status}  
              link={`/card/${card._id}`}
              key={card._id} 
          />
        ))}
    </Card>
  )
}

export default CardBlock