import React from 'react'
import styled from 'styled-components/macro'
import { useSelector } from 'react-redux'
import { selectIsAuth } from '../../redux/slices/auth'
import axios from '../../utils/axios'
import { useTranslation } from 'react-i18next'
import logo from '../../assets/logo.png'

const BannerCard = styled.div`
height: fit-content;
position: absolute;
top: -99px;
left: 0;
right: 0;
max-width: 1400px;
width: 100%;
margin: auto;

@media (max-width: 577px){
  width: 100%;
}
`
const Image = styled.div`
height: fit-content;
position: absolute;
top: -99px;
left: 0;
right: 0;
max-width: 1400px;
width: 100%;
margin: auto;

img{
  height: fit-content;
  width:  fit-content;
  object-fit: cover;
  max-width: 1400px;
  max-height: 255px;
  margin: 0 auto;
  display: block;
}

@media (max-width: 577px){
  width: 100%;
  top: -65px;

  img{
    max-width: 100%;
    height: 130px;
    object-fit: cover;
    width: 100%;
  }
}
`
const BtnAdd = styled.button`
background-color: #f2eee9;
border: 1px solid #e6dede;
width: 220px;
top: 0;
height: 45px;
position: absolute;
font-size: 12pt;
border-radius: 15px;
margin-top: 2rem;
right: 20px;
opacity: 0.5;
transition: 0.3s ease;
font-family: 'Montserrat';
font-weight: 500;

&:hover{
  opacity: 1;
}
@media (max-width: 577px){
  display: none;
  position: absolute;
  margin-top: 0rem;
  right: 55%;
  width: 180px;
}
`
const Save = styled.button`
width: 220px;
height: 45px;
background: rgba(254, 97, 3, 0.5);
border: 1px solid rgba(0, 0, 0, 0);
border-radius: 15px;
font-family: 'Montserrat';
font-size: 17px;
top: 14rem;
right: 12.3rem;
position: absolute;
opacity: 0.5;
z-index: 12;
transition: 0.3s ease;

&:hover{
  opacity: 1;
}

@media (max-width: 577px){
  position: absolute;
  margin-top: -3rem;
  right: 55%;
  width: 180px;
}
`
const BtnDelete = styled.button`
background-color: red;
border: 1px solid #e6dede;
width: 220px;
height: 45px;
position: absolute;
font-size: 12pt;
border-radius: 15px;
margin-top: 6rem;
margin-left: -15rem;
opacity: 0.5;
transition: 0.3s ease;
font-family: 'Montserrat';
font-weight: 500;

&:hover{
  opacity: 1;
}
`


const Banner = () => {
  const isAuth = useSelector(selectIsAuth)

  const [bannerUrl, setBannerUrl] = React.useState(null)
  const [isLoading, setLoading] = React.useState('')
  const [isShown, setIsShown] = React.useState(false);
  const { t } = useTranslation()

  const inputFileRefBanner = React.useRef('')


  const handleChangeBanner = async (event) => {

    try {
      const formData = new FormData();
      const files = event.target.files[0]
      formData.append('banner', files);
      const { data } = await axios.post('/upload/banner', formData)
      setBannerUrl(data.url)
      setIsShown(!isShown)
  } catch (err) {
      console.warn(err);
      alert('Ошибка при загрузке изображения')
  }

  }



  const onSubmit = async () => {
    try {
        setLoading(true)

        const fields ={
            bannerUrl,
        }

        const { data } = await axios.patch('/auth/me', fields)

        const id = data._id

        console.log(id)
        window.location.reload()
    } catch (err) {
        console.warn(err)
        alert('Ошибка при загрузке товара')
    }
}

console.log('isAuth', isAuth)
  return (
    <>
    <input ref={inputFileRefBanner} name='banner' type='file' onChange={handleChangeBanner} encType="multipart/form-data"  hidden/>
    
    {isAuth.bannerUrl ? 
      <Image>
        <img alt='banner' src={`https://toobears.com/api${isAuth.bannerUrl}`} />
        <BtnAdd onClick={() => inputFileRefBanner.current.click()}>{t('chngBanner')}</BtnAdd>
        {isShown && <Save onClick={onSubmit}>{t('saveBanner')}</Save>}
      </Image>
    :
      <BannerCard>
          <BtnAdd onClick={() => {inputFileRefBanner.current.click()}}>{t('chngBanner')}</BtnAdd>
          {isShown && <Save onClick={onSubmit}>{t('saveBanner')}</Save>}
      </BannerCard>
    }
    </>
  )
}

//  

export default Banner