import React from 'react'
import StatusText from './StatusText'
import ActivityText from './ActivityText'
import OrderList from '../OrderList'
import styled from 'styled-components/macro'

const OrderWrapper = styled.div`
  padding-left: 25px;
  margin-bottom: 50px;
`

const Homepage = () => {
  return (
    <>
    <StatusText />
    <ActivityText />
    </>
  )
}

export default Homepage