import styled from 'styled-components/macro'
import closeSVG from './../../MessageModal/close.svg';
import { useState } from 'react';
import axios from './../../../utils/axios';
import { useTranslation } from 'react-i18next'
import alarm from './alarm_on_24px.svg';

const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
`
const Modal = styled.div`
    width: 860px;
    background: #FFFFFF;
    padding: 30px 0 30px;
    border-radius: 10px;

    @media (max-width: 577px){
        width: 362px;
        padding: 14px 0;
    }
`

const ModalTopWrapper = styled.div`
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(148, 148, 148, 0.5);

    @media (max-width: 577px){
        padding-bottom: 0;
    }
`
const ModalTop = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 45px;

    @media (max-width: 577px){
        padding: 0 17px 16px;
    }
`

const ModalCloseBtn = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
`

const ModalTitle = styled.div`
    font-weight: 400;
    font-size: 25px; 
    line-height: 25px;
    color: #000000;

    @media (max-width: 577px){
        font-size: 14px;
    }
`

const ModalMain = styled.div`
   padding: 10px 60px 0 35px;
   margin-bottom: 10px;

   @media (max-width: 577px){
        padding: 26px 17px 0;
    }
`
const ButtonSend = styled.button`
   border: none;
   cursor: pointer;
   padding: 7px 17px;
   text-align: center;
   height: 40px;
   background: 	rgba(254,97,3,0.15);
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 4px rgba(0, 0, 0, 0.04);       
   border-radius: 10px;   
   font-size: 1p4x;  
   
   @media (max-width: 577px){
        display: none;
    }
`

const ButtonSendMobile = styled.button`
   display: none;
   
   @media (max-width: 577px){
        background: rgba(254,97,3,0.15);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 4px rgba(0, 0, 0, 0.04);
        border-radius: 10px;  
        border: none;
        height: 25px;
        width: 100px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 60px 0 35px;

    @media (max-width: 577px){
        padding: 0 17px 0 0;
    }
`
const Label = styled.label`
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    @media (max-width: 577px){
        font-size: 12px;
        margin-bottom: 10px;
        display: block;
    }
`

const LabelText = styled.div`
    color: #000;
    font-family: Montserrat;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    padding-top: 5px;

    @media (max-width: 577px){
        font-size: 13px;
        margin-bottom: 6px;
    }
`

const Input = styled.input`
    box-sizing: border-box;
    padding: 7px 20px;
    width: 600px;
    height: 40px;
    border: 2px solid rgba(171, 154, 144, 0.50); 
    background: white;
    border-radius: 10px;
    outline: none;
    font-size: 16px;

    @media (max-width: 577px){
        font-size: 12px;
        width: 100%;
        height: 25px;
    }
`

const Textarea = styled.textarea`
    font-size: 16px;
    box-sizing: border-box; 
    resize: none;
    padding: 10px 20px;
    width: 600px;
    height: 160px;
    border: 2px solid rgba(171, 154, 144, 0.50); 
    background: white;
    border-radius: 10px;
    outline: none;

    @media (max-width: 577px){
        font-size: 12px;
        width: 100%;
        height: 100px;
    }
`

const RemarkBlock = styled.div`
    display: flex;
    align-items: center;
    padding: 20px 60px 15px 35px;

    @media (max-width: 577px){
        font-size: 12px;
        padding: 20px 17px 0;
        align-items: flex-start;
    }
`

const RemarkImg = styled.img`
    display: block;
    margin-right: 24px;
    width: 30px;

    @media (max-width: 577px){
        margin-right: 9px;
        width: 20px;
    }
`

const RemarkText = styled.div`
    color: #000;
    font-family: Montserrat;
    font-size: 20px;
    line-height: 25px; 

    @media (max-width: 577px){
        font-size: 13px;
        line-height: 15px; 
    }
`

const ContactModal = ({onClose}) => {
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [message, setMessage] = useState('')
    const { t } = useTranslation();

    const changeEmail = (e) => {
        setEmail(e.target.value)
    }

    const changeName = (e) => {
        setName(e.target.value)
    }

    const changeMessage = (e) => {
        setMessage(e.target.value)
    }

    const sendEmail = async (e, name, email, message) => {
        e.preventDefault();
        e.stopPropagation();

        await axios.post('/support', {name, email, message});
        alert(t('mesWasSent'));
        onClose()
    }

    return (
        <ModalWrapper>
            <Modal>
                <ModalTopWrapper>
                    <ModalTop>
                        <ModalTitle>
                            {t('contactUs')}
                        </ModalTitle>
                        <ModalCloseBtn onClick={onClose}>
                            <img alt='close' src={closeSVG} />
                        </ModalCloseBtn>
                    </ModalTop>
                </ModalTopWrapper>
                <RemarkBlock>
                    <RemarkImg alt='icon alarm' src={alarm}/>
                    <RemarkText>
                        {t('helpYou')}
                    </RemarkText>
                </RemarkBlock>
                <form onSubmit={(e) => sendEmail(e, name, email, message)}>
                    <ModalMain>
                        <Label>
                            <LabelText>
                                {t('fullName')}
                            </LabelText>
                            <Input value={name} type='text' onInput={changeName}/>
                        </Label>
                        <Label>
                            <LabelText>
                                {t('Email')}
                            </LabelText>
                            <Input value={email} type='email' onInput={changeEmail}/>
                        </Label>
                        <Label>
                            <LabelText>
                                {t('yourMes')}
                            </LabelText>
                            <Textarea value={message} type='text' onInput={changeMessage}/>
                        </Label>
                    </ModalMain>
                    <ButtonWrapper>
                        <ButtonSend type='submit'>{t('sendMessage')}</ButtonSend>
                        <ButtonSendMobile type='submit'>{t('send')}</ButtonSendMobile>
                    </ButtonWrapper>
                </form>
            </Modal>
        </ModalWrapper>
    )
}

export default ContactModal;