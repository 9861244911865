import styled from 'styled-components/macro';

export const Root = styled.div`
    box-sizing: border-box;
    padding: 45px 250px 250px;
    max-width: 1920px;
    margin: 0 auto;

    @media (max-width: 1024px){
        padding: 40px 111px;
    }
    
    @media (max-width: 414px){
        padding: 30px 20px;
    }
`

export const TitlePage = styled.div`
    padding-bottom: 8px;
    font-size: 32px;
    font-weight: 400;
    font-family: Palatino Linotype;
    color: rgba(0,0,0,0.80);
    opacity: 0.8;

    @media (max-width: 1024px){
        padding-bottom: 5px;
        font-size: 30px;
    }

    @media (max-width: 414px){
        padding-bottom: 5px;
        font-size: 25px;
    }
`

export const Title = styled.div`
    color: rgba(0, 0, 0, 0.80);
    font-family: Palatino Linotype;
    font-size: 32px;
    font-weight: 400;
    opacity: 0.8;

    @media (max-width: 1024px){
        font-size: 22px;
    }
    
    @media (max-width: 414px){
        font-size: 20px;
    }
`

export const Border = styled.div`
    opacity: 0.5;
    background: rgba(148, 148, 148, 0.50);
    height: 1px;
    width: 100%;
    margin-bottom: 40px;

    @media (max-width: 414px){
        margin-bottom: 30px;
    }
`

export const Text = styled.div`
    color: #000;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    opacity: 0.8;
    margin-top: 20px;
    margin-bottom: 22px;

    @media (max-width: 1024px){
        font-size: 20px;
    }
    
    @media (max-width: 414px){
        font-size: 15px;
        line-height: 21px;
    }
`

export const Date = styled(Text)`
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
    text-align: end;

    @media (max-width: 1024px){
        font-size: 18px;
    }
    
    @media (max-width: 414px){
        font-size: 16px;
        line-height: 20px;
    }
`

