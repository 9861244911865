import { useTranslation } from 'react-i18next'
import {Root, Title, Description, SubTitle, ButtonContact} from './styles';
import { useState } from 'react';
import ContactModal from './modal/contact-modal';

const Recomendation = () => {
    const { t } = useTranslation();
    const [isOpenModal, setIsOpenModal] = useState(false);

    return (
        <Root>
            <Title>{t('RTitle')}</Title>
            <Description>
                {t('RDesc1')}
                <br/>
                <br/>
                {t('RDesc2')}
                <ul>
                    <li>
                    {t('RDesc3')}
                    </li>
                    <li>
                    {t('RDesc4')}
                    </li>
                    <li>
                    {t('RDesc5')}
                    </li>
                    <li>
                    {t('RDesc6')}
                    </li>
                    <li>
                    {t('RDesc7')}
                    </li>
                </ul>
                <br/>
                {t('RDesc8')}
                <ul>
                    <li>
                    {t('RDesc9')}
                    </li>
                    <li>
                    {t('RDesc10')}
                    </li>
                    <li>
                    {t('RDesc11')}
                    </li>
                    <li>
                    {t('RDesc12')}
                    </li>
                    <li>
                    {t('RDesc13')}
                    </li>
                </ul>
                <br/>
                {t('RDesc14')}
                <br/>
                {t('RDesc15')}
            </Description>
            <SubTitle>
                {t('RSubTitle1')}
            </SubTitle>
            <Description>
                {t('RDesc16')}
                <br/>
                <br/>
                {t('RDesc17')}
                <br/>
                <br/>
                {t('RDesc18')}
                <ButtonContact onClick={() => setIsOpenModal(true)}>{t('RDesc19')}</ButtonContact>
                {t('RDesc20')}
                <br/>
                <br/>
                {t('RDesc21')}
            </Description>
            {isOpenModal &&
                <ContactModal onClose={() => setIsOpenModal(false)} />
            }
        </Root>
    )
}

export default Recomendation;