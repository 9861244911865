import { useTranslation } from 'react-i18next'
import {Root, BannerBear, MainText, 
     MainTextTitle, ParticipantListTitle, ShowListTitle, OurParticipatns,
      ShowListSmall, OurBenefits, ShowListBenefitItem, ShowByeTitle,
      OurJury, OurJuryWrapper, OurJuryItem, JuryAvatar, JuryName,
      JuryCountry, JuryDesc, JuryCountryMark, JuryCountryName, JuryAvatarWrapper, Remark,
      JuryAvatarMask,
      ShowList,
      ShowListItem,
      ShowListItemGreen,
      ShowListItemNotPoint
} from '../styles';
import Participants from './participants/participants';
import MarkIcon from './assets/mark.svg';

import First from './assets/first-jury.png';
import Second from './assets/second-jury.png';
import JuriMask from './assets/wrapper-juri.png';


const OnlineShowParticipant = () => {
    const { t } = useTranslation();

    return (
        <Root>
            <BannerBear></BannerBear>
            <MainText>
                <MainTextTitle><i>{t('ToobearShow')}</i> - </MainTextTitle>
                {t('ToobearShowDesc')}
            </MainText>
            <MainText>{t('ShowDesc2')}</MainText>
            <OurParticipatns>
                <ParticipantListTitle><i>{t('Participants')}</i></ParticipantListTitle>
                <Participants/>
            </OurParticipatns>
            <OurJury>
                <ParticipantListTitle><i>{t('OurJury')}</i></ParticipantListTitle>
                <OurJuryWrapper>
                    <OurJuryItem>
                        <JuryAvatarWrapper>
                            <JuryAvatarMask alt='mask' src={JuriMask}/>
                            <JuryAvatar alt='avatar' src={First}/>
                        </JuryAvatarWrapper>
                        <JuryName>
                            {t('QiuYihua')}
                        </JuryName>
                        <JuryCountry>
                            <JuryCountryMark alt='country' src={MarkIcon}/>
                            <JuryCountryName>{t('china')}</JuryCountryName>
                        </JuryCountry>
                        <JuryDesc>
                            {t('QuiDesc')}
                        </JuryDesc>
                    </OurJuryItem>
                    <OurJuryItem>
                        <JuryAvatarWrapper>
                            <JuryAvatarMask alt='mask' src={JuriMask}/>
                            <JuryAvatar alt='avatar' src={Second} />
                        </JuryAvatarWrapper>
                        <JuryName>
                            {t('RichardBackschas')}
                        </JuryName>
                        <JuryCountry>
                            <JuryCountryMark alt='country' src={MarkIcon}/>
                            <JuryCountryName>{t('germany')}</JuryCountryName>
                        </JuryCountry>
                        <JuryDesc>
                            {t('RichDesc1')}
                            {t('RichDesc2')} <br/><br/>
                            {t('RichDesc3')} <br/><br/>
                            {t('RichDesc4')}
                        </JuryDesc>
                    </OurJuryItem>
                </OurJuryWrapper>
            </OurJury>
            <ShowListTitle><i>{t('ShowRules')}</i></ShowListTitle>
            <ShowList>
                <ShowListItem><i><b>{t('ShowItem1/2')}</b></i>{t('ShowItem2/2')}</ShowListItem>
                <ShowListItem>{t('ShowItem2-1/2')}<ShowListItemGreen><i><b>{t('ShowItem2-2/2')}</b></i></ShowListItemGreen></ShowListItem>
                <ShowListItem>{t('ShowItem3')}</ShowListItem>
                <ShowListItem>{t('ShowItem4')}</ShowListItem>
                <ShowListItem>{t('ShowItemPrice')}</ShowListItem>
                <ShowListItem><b><i>{t('ShowItem5-1/2')}</i>{t('ShowItem5-2/2')}</b></ShowListItem>
                <ShowListItem>{t('ShowItem6')}</ShowListItem>
                <ShowListItem>{t('ShowItem8')}</ShowListItem>
                <ShowListItem>{t('ShowItem9-1/2')}<b><i>{t('ShowItem9-2/2')}</i></b></ShowListItem>
                <ShowListSmall>
                    <ShowListItem>{t('ShowItemSmall1')}</ShowListItem>
                    <ShowListItem>{t('ShowItemSmall2')}</ShowListItem>
                    <ShowListItem>{t('ShowItemSmall3')}</ShowListItem>
                </ShowListSmall>
                <ShowListItemNotPoint>{t('ShowItemSmall4')} <b>{t('ShowItemSmall5')}</b></ShowListItemNotPoint>
                    <ShowListSmall>
                        <ShowListItem>{t('ShowItemSmall6')}</ShowListItem>
                    </ShowListSmall>
                <ShowListItem>{t('ShowItem10-1/3')}<b><i>{t('ShowItem10-2/3')}</i></b>{t('ShowItem10-3/3')}</ShowListItem>
                <ShowListItem>{t('ShowItem11')}</ShowListItem>
            </ShowList>
            <OurBenefits>
                <ShowListTitle><i>{t('OurBenefits')}</i></ShowListTitle>
                <ShowListSmall>
                    <ShowListBenefitItem>{t('OurBenefitsItem1')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('OurBenefitsItem2')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('OurBenefitsItem3')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('OurBenefitsItem4')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('OurBenefitsItem5')}</ShowListBenefitItem>
                </ShowListSmall>
            </OurBenefits>
            <Remark>
                <i><b>{t('AnnouncementRemarkBold')}</b></i>{t('AnnouncementRemark')}
            </Remark>
            <ShowByeTitle>
                <i>{t('ByeShoe')}</i>
            </ShowByeTitle>
        </Root>
    )
}

export default OnlineShowParticipant;