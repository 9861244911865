import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import axios from '../utils/axios'
import eyes from './../assets/eyes.svg'
import { Link } from 'react-router-dom'


const Head = styled.div`
    box-sizing: border-box;
    font-size: 26px;
    display: flex;
    padding-top: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(148, 148, 148, 0.5) ;
    font-family: 'Palatino';

    @media (max-width: 577px){
        width: 90%;
        margin: 0 20px;
    }
`

const TableWrapper = styled.div`

    @media (max-width: 577px){
        padding: 0 20px;
    }
`

const Table = styled.table`
    border-collapse: collapse;
    width: 100%;
    color: #000000;
    font-family: 'Montserrat';
    box-sizing: border-box;

    tr {
        border-bottom: 1px solid rgba(148, 148, 148, 0.7);
    }

    td {
        padding: 7px 0;
    }

    th {
        padding: 7px 0;
        text-align: start;
        font-size: 19px;
        opacity: 0.8;
    }
`

const MobileHiddenCell = styled.td`
    @media (max-width: 577px){
      display: none;
    }
`

const MobileHiddenHeadCell = styled.th`
    @media (max-width: 577px){
      display: none;
    }
`

const OrderNameWrapper = styled.div `
    display: flex;
`

const OrderImage = styled.div`
    display: flex;

    img {
        width: 62px;
        margin-right: 14px;
    }

    @media (max-width: 577px){

    }
`

const OrderName = styled.div`
    font-size: 15px;
    margin: auto 0;
    img {
        display: block;
    }
`

const ShowMenu = styled.div`
    display: flex;
    padding: 15px 0;
    box-sizing: border-box;

    @media (max-width: 577px){
        padding: 15px 20px;
    }
`

const StateFilterBtn = styled.button`
    border: none;
    background: none;
    margin-right: 15px;
    font-size: 19px;
    font-family: 'Montserrat';
    font-weight: 500;
    box-sizing: border-box;
    cursor: pointer;
    border-bottom: ${props => props.isActive ? '5px solid rgba(254, 97, 3, 0.2)' : '5px solid transparent'};

    &:hover {
        border-bottom: 5px solid rgba(254, 97, 3, 0.2);
    }
`

const EyesIcon = styled.img`
    display: block;
    margin: 0 auto;
    cursor: pointer;
`

const OrderList = () => {
  const { t } = useTranslation()
  const [showState, setShowState] = useState('active');
  const [orders, setOrders] = useState(null);

  const getOrders = useCallback(async () => {
    const { data } = await axios.get(`/orders`);
    setOrders(data);
  }, [])

  useEffect(() => {
    getOrders()
  }, [getOrders])

  const formatStatus = (item) => {
    const status = {
        0: t('inProccess'),
        1: t('paid'),
        2: t('delivery'),
        3: t('completeOrder')
      }
    return item = status[item];
  }

  const formatDate = (newDate) => {
    const months = {
        0: '01',
        1: '02',
        2: '03',
        3: '04',
        4: '05',
        5: '06',
        6: '07',
        7: '08',
        8: '09',
        9: '10',
        10: '11',
        11: '12',
      }
    const d = newDate
    const year = d.getFullYear()
    const date = d.getDate()
    const monthName = months[d.getMonth()]
    const formatted = `${date}.${monthName}.${year}`
    return formatted.toString()
  }

  return (
    <div>
        <Head>
            {t('myOrder')}
        </Head>
        <ShowMenu>
            <StateFilterBtn onClick={() => setShowState('active')} isActive={showState === 'active'}>
                {t('active')}
            </StateFilterBtn>
            <StateFilterBtn onClick={() => setShowState('completed')} isActive={showState === 'completed'}>
                {t('completed')}
            </StateFilterBtn>
        </ShowMenu>
        {orders &&
            (<TableWrapper>
                <Table>
                    <thead>
                        <tr>
                            <th>{t('artWork')}</th>
                            <MobileHiddenHeadCell>{t('status')}</MobileHiddenHeadCell>
                            {/* <MobileHiddenHeadCell>{t('trackingNumber')}</MobileHiddenHeadCell> */}
                            <MobileHiddenHeadCell>{t('order')}</MobileHiddenHeadCell>
                            <th>{t('date')}</th>
                            <th>{t('showing')}</th>
                        </tr>
                    </thead>
                    <tbody >
                        {orders.filter(({ status, card }) => {
                            switch(showState) {
                                case 'completed':
                                    return !!card && status === 3;
                                case 'active':
                                default:
                                    return !!card && status !== 3;
                            }
                        }).map((item, i) => (
                                <tr key={i}>
                                    <td>
                                        <OrderNameWrapper>
                                            <OrderImage>
                                                <img src={`https://toobears.com/api${item.card.imgUrl?.[0]}`} alt='Order-img'/>
                                            </OrderImage>
                                            <OrderName>
                                                {item.card.name}
                                            </OrderName>
                                        </OrderNameWrapper>
                                    </td>
                                    <MobileHiddenCell>
                                        {formatStatus(item.status)}
                                    </MobileHiddenCell>
                                    {/* <MobileHiddenCell>
                                        {item.deliveryCode}
                                    </MobileHiddenCell> */}
                                    <MobileHiddenCell>{item.orderId}</MobileHiddenCell>
                                    <td>{formatDate(new Date(item.createdAt))}</td>
                                    <td>
                                        <Link to={`/account/orderInfo/${item._id}`}>
                                            <EyesIcon alt='show' src={eyes}/>
                                        </Link>
                                    </td>
                                </tr>

                        ))}
                    </tbody>
                </Table>
            </TableWrapper>
            )
        }
    </div>
  )
}

export default OrderList
