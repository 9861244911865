import React from 'react';
import styled from 'styled-components/macro';
import checked from './ckeck.svg';
import { useState } from 'react'
import { useTranslation } from 'react-i18next';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    color: rgba(0, 0, 0, 0.7);
    max-width: 700px;
    margin: 0 auto;
    box-sizing: border-box;
    font-family: 'Montserrat';

    @media (max-width: 577px){
        max-width: 400px;
        padding: 50px 10px 10px;
    }
`

const CardImgContainer = styled.div`

`;

const CardContainer = styled.div`
    padding-right: 180px;

    @media (max-width: 577px){
        padding-right: 0;
    }
`

const CardImg = styled.img`
    width: 509px;
    border-radius: 10px;

    @media (max-width: 577px){
        width: 212px;
    }
`;

const CardName = styled.div`
    margin-top: 20px;
    margin-bottom: 4px;
    font-weight: 400;
    font-size: 30px;
    line-height: 30px;
    color: rgba(0, 0, 0, 0.7);
    text-align: center;

    @media (max-width: 577px){
        font-size: 20px;
        margin-bottom: 0;
    }
`;

const CardAuthor = styled.div`
    margin-bottom: 27px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;

    @media (max-width: 577px){
        font-size: 14px;
    }
`;

const Pay = styled.div`
    margin-bottom: 45px;

    @media (max-width: 577px){
        margin-bottom: 25px;
        display: flex;
        flex-direction: column;
        align-self: baseline;
    }
`;

const PayTitle = styled.div`
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;

    @media (max-width: 577px){
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px;
    }
`;

const PayContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 5px;
`;

const PayMethod = styled.div`
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;

    @media (max-width: 577px){
        font-size: 15px;
    }
`;

const CheckboxContainer = styled.label`
     display: block;
    position: relative;
    margin-right: 12px;
    width: 19px;
    height: 19px;
    box-sizing: border-box;
`;

const Checkmark = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 19px;
    height: 19px;
    background-image: url(${checked});
    background-position: center;
`;

const PayInput = styled.input`
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
`;

const PayText = styled.div`
    font-size: 16px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.5);

    @media (max-width: 577px){
        font-size: 13px;
        line-height: 18px;
    }
`;

const FormPay = styled.form`
    width: 100%;
`

const FormTitle = styled.div`
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 32px;

    @media (max-width: 577px){
        font-size: 20px;
        margin-bottom: 25px;
    }
`

const FormItem = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
`

const FormItemName = styled.label`
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 577px){
        font-size: 15px;
        flex-direction: column;
        align-items: flex-start;
    }
`

const FormItemInput = styled.input`
    border-radius: 10px;
    border: none;
    outline: none;
    background: #F5F5F5;
    padding: 9px 15px;
    width: 450px;
    height: 35px;
    box-sizing: border-box;

    @media (max-width: 577px){
        height: 25px;
        padding: 5px 10px;
        width: 350px;
        font-size: 13px;
    }

    @media (max-width: 380px){
        width: 310px;
    }
`

const MessageBlock = styled.div`
    margin-top: 25px;
    width: 100%;
    margin-bottom: 18px;
`

const MessageTitle = styled.div`
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 20px;

    @media (max-width: 577px){
        font-size: 15px;
        margin-bottom: 15px;
    }
`

const MessageWrapper = styled.div`
    display: flex;
    justify-content: space-between;

    @media (max-width: 577px){
        flex-direction: column;
        align-items: flex-start;
    }
`
const MessageDesc = styled.div`
    padding-top: 10px;
    font-size: 16px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.5);
    width: 200px;

    @media (max-width: 577px){
        display: none;
    }
`
const MessageArea = styled.textarea`
    border-radius: 10px;
    border: none;
    outline: none;
    background: #F5F5F5;
    box-sizing: border-box;
    resize: none;
    height: 150px;
    width: 450px;
    padding: 5px 15px;

    @media (max-width: 577px){
        height: 125px;
        width: 350px;
        font-size: 13px;
    }

    @media (max-width: 380px){
        width: 310px;
    }
`

const BtnContainer = styled.div`
    display: flex;
    justify-content: center;
`

const FormBtn = styled.button`
    margin-top: 80px;
    border: none;
    background: #FE6103;
    opacity: 0.7;
    border-radius: 10px;
    color:  #F5F5F5;
    font-size: 25px;
    width: 300px;
    height: 55px;
    padding: 0 10px;
    cursor: pointer;

    @media (max-width: 577px){
        margin-top: 24px;
        width: 245px;
        font-size: 17px;
        height: 45px;
    }
`

const BuyPage = (props) => {
    const {t} = useTranslation();

    const send = props.onSend;
    const card = props.card;
    const [formValues, setFormValues] = useState({
        shippingName: '',
        shippingCountry: '',
        shippingCity: '',
        shippingState: '',
        shippingAddress: '',
        shippingPortalCode: '',
        shippingPhone: '',
        message: ''
    });

    const formHandler = (e) => {
        setFormValues({
            ...formValues,
            [e.currentTarget.name]: e.currentTarget.value
        })
    }

    const formSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        await send(formValues);
    }

    return (
        <Container>
            <CardContainer>
                <CardImgContainer>
                    <CardImg src={`https://toobears.com/api/${card.imgUrl?.[0]}`}/>
                </CardImgContainer>
                <CardName>
                    {card.name}
                </CardName>
                <CardAuthor>
                    {t('by')} {card.author.username}{''}{card.author.surname}
                </CardAuthor>   
            </CardContainer>  
            <Pay>
                <PayTitle>{t('payTitle')}</PayTitle>
                <PayContainer>
                    <CheckboxContainer>
                        <PayInput type='checkbox' checked/>
                        <Checkmark/>
                    </CheckboxContainer>
                    <PayMethod>{t('payMethodName')}</PayMethod>
                </PayContainer>
                <PayText>{t('payDesc')}
                </PayText>
            </Pay>
            <FormPay onSubmit={formSubmit}>
                <FormTitle>
                    {t('shippingTitle')}
                </FormTitle>
                <FormItem>
                    <FormItemName>
                        <div>{t('nameShip')}</div>
                        <FormItemInput required name='shippingName' value={formValues.name} onChange={formHandler}/>
                    </FormItemName>
                </FormItem>
                <FormItem>
                    <FormItemName>
                        <div>{t('countryShip')}</div>
                        <FormItemInput required name='shippingCountry' value={formValues.country} onChange={formHandler}/>
                    </FormItemName>
                </FormItem>
                <FormItem>
                    <FormItemName>
                        <div>{t('cityShip')}</div>
                        <FormItemInput required name='shippingCity' value={formValues.city} onChange={formHandler}/>
                    </FormItemName>
                </FormItem>
                <FormItem>
                    <FormItemName>
                        <div>{t('stateShip')}</div>
                        <FormItemInput required name='shippingState' value={formValues.state} onChange={formHandler}/>
                    </FormItemName>
                </FormItem>
                <FormItem>
                    <FormItemName>
                        <div>{t('addressShip')}</div>
                        <FormItemInput required name='shippingAddress' value={formValues.address} onChange={formHandler}/>
                    </FormItemName>
                </FormItem>
                <FormItem>
                    <FormItemName>
                        <div>{t('postalCodeShip')}</div>
                        <FormItemInput required name='shippingPortalCode' value={formValues.portalCode} onChange={formHandler}/>
                    </FormItemName>
                </FormItem>
                <FormItem>
                    <FormItemName>
                        <div>{t('phoneShip')}</div>
                        <FormItemInput required name='shippingPhone' value={formValues.phone} onChange={formHandler}/>
                    </FormItemName>
                </FormItem>
                <MessageBlock>
                    <MessageTitle>
                        {t('messageShip')}
                    </MessageTitle>
                    <MessageWrapper>
                        <MessageDesc>
                            {t('messageDesc')}
                        </MessageDesc>
                        <MessageArea name='message' value={formValues.message} onChange={formHandler}/>
                    </MessageWrapper>
                </MessageBlock> 
                <BtnContainer>
                    <FormBtn>
                        <b>{t('placeOrder')}</b>
                    </FormBtn>
                </BtnContainer>
            </FormPay>
        </Container>
    )
};

export default BuyPage;