import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { fetchRegister } from '../../redux/slices/auth'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import CustomRulesTextField from '../../ui-kit/custom-rules-text-field/custom-rules-text-field'
import { validateEmail } from '../../utils/validation/email'

const FormBlock = styled.div`
    position: relative;
    margin: 2rem 2.4rem;
    width: 400px;
    input{
        margin-top: 1rem;
        width: 350px;
        height: 35px;
        border: 1px solid #f1f1f1;
        background: #f1f1f1;
        border-radius: 5px;
        outline: none;
        text-indent: 10px;
    }
    img{
        position: absolute;
        width: 16px;
        margin-left: 5px;
        margin-top: 3px;
        opacity: 0.3;
    }

    @media (max-width: 577px){
        width: auto;

        input {
            width: 100%;
        }
    }
`
const Name = styled.label`
    font-weight: 400;
    font-size: 17px;
    position: relative;
    display: block;
    color: ${props => props.errorTitle && '#FB0808'};

    img{
        height: 25px;
        width: 20px;
        opacity: 0.5;
        position: absolute;
        right: 14px;
        top: 43px;
    }
`

const PassBlock = styled.div`
    margin: 1rem 2.4rem;
    input{
        margin-top: 1rem;
        width: 350px;
        height: 35px;
        border: 1px solid #f1f1f1;
        background: #f1f1f1;
        border-radius: 5px;
        outline: none;
        text-indent: 10px;
    }

    @media (max-width: 577px){
        input {
            width: 100%;
        }
    }
`

const BtnSubmit = styled.button`
    position: relative;
    width: 235px;
    height: 56px;
    background-color: rgba(254, 97, 3, 0.2);
    color:  #000;
    outline: none;
    border: 0px solid #808080;
    font-family: Montserrat;
    font-weight: 400;
    font-size: 16px;
    border-radius: 10px;
    cursor: pointer;
    margin-left: 2.4rem;
    margin-top: 1rem;

    
    :disabled {
        cursor: not-allowed;
    }
`

const Block = styled.div`
    width: 437px;
    border-top: 1px solid rgba(171, 154, 144, 0.6);
    height: 93px;
    margin-top: 1.6rem;
    margin-left: -1px;

    @media (max-width: 577px){
        width: 100%;
    }
`
const Text = styled.p`
    width: 330px;
    padding: 1rem 2.4rem;
    font-size: 15px;

    span{
        text-decoration: underline;
    }
    a {
        text-decoration: underline;
    }

    @media (max-width: 577px){
        width: auto;
    }
`

export const ErrorText = styled.div`
    color: #FB0808;
    font-size: 13px;
    line-height: 22px;
    font-weight: 500;
    font-family: 'Montserrat';
    margin-top: 5px;   
     
    @media (max-width: 414px){
        margin-left: ${props => props.marginLeft ? '1.2rem' : '0'};
    }
`

const RegisterCollector = () => {
    const { t } = useTranslation()
    const Rules =  [{ regex: /^[a-zA-Z0-9 ]+$/, errorText: t('OnlyEnglishRule') }, {isShort: 2, errorText: t('LongerThen2Rule')}]
    const [isShowPass, setIsShowPass] = useState(false);
    const dispatch = useDispatch()
    const { register, handleSubmit, setValue, watch} = useForm({
      defaultValues:{
          username: '',
          surname: '',
          email: '',
          password: '',
          role: 'User'
      },
      mode: 'onChange'
    });
    const [errors, setErrors] = useState({});
    const isFormFilled = Object.values(watch()).every((v) => v.length > 0) && Object.values(watch().password).length > 7
    const disabledBtnRegister = !isFormFilled || errors.surname || errors.username;

    const onSubmit = async (values) => {
    // eslint-disable-next-line no-undef
        grecaptcha.execute('6LdtmlwoAAAAAB5bqGua0VLiphFLK4TJJXsjFJs6', { action: 'submit' }).then(async function(token) {
            const data = await dispatch(fetchRegister({...values, 'g-recaptcha-response': token}))
            if (!data.payload) {
                return alert(t('failRegister'))
            }
        
            if ('token' in data.payload){
                window.localStorage.setItem('token', data.payload.token)
            }
            
            window.location.assign('/account')
        });
    };

    const inputChangeHandler = (value, e) => {
        setValue(e.target.name, value);
        setErrors((prev) => ({
            ...prev,
            [e.target.name]: null
        }));
    }
    
    const inputErrorHandler = (err, value, e) => {
        setValue(e.target.name, value);
        setErrors((prev) => ({
            ...prev,
            [e.target.name]: err
        }));
    };

  return (
    <>
        <form id="my_captcha_form" onSubmit={handleSubmit(onSubmit)}>
        <div className="g-recaptcha" data-sitekey="6LdtmlwoAAAAAB5bqGua0VLiphFLK4TJJXsjFJs6" />
        <FormBlock style={{marginTop: '0.5rem'}}>
            <Name errorTitle={errors.username}>{t('name')}</Name>
            <CustomRulesTextField
                {...register('username')}
                rules={Rules}
                onChange={inputChangeHandler}
                onError={inputErrorHandler}

            />
        </FormBlock>
        <FormBlock>
            <Name errorTitle={errors.surname}>{t('surname')}</Name>
            <CustomRulesTextField
                {...register('surname')}
                rules={Rules}
                onChange={inputChangeHandler}
                onError={inputErrorHandler}
            />
        </FormBlock>
        <FormBlock>
            <Name>Email</Name>
            <input type="text" size="0" id="email" {...register('email', { required: 'Укажите почту'})} />
            {!validateEmail( watch('email')) && watch().email.length !== 0 &&
                <ErrorText>{t('EmailRuleRule')}</ErrorText>
            }
        </FormBlock>
        <PassBlock>
            <Name>
                {t('password')}
                <input type={isShowPass ? "text" : "password"} size="0" placeholder={t('noless')}  id="password" {...register('password', { required: 'Укажите пароль'})} />
                <img alt='lock' onClick={() => setIsShowPass(!isShowPass)} src='https://svgshare.com/i/meB.svg' title='lock' />
            </Name>
            {watch().password.length < 7 && watch().password.length !== 0 &&
                <ErrorText>{t('PasswordRule')}</ErrorText>
            }
        </PassBlock>
        <FormBlock hidden >
            <Name>Роль</Name>
            <input type="text" size="0" id="role" {...register('role')} />
        </FormBlock>
        <BtnSubmit disabled={disabledBtnRegister} data-badge="inline"  type="submit">
                <span>{t('register')}</span>
        </BtnSubmit>
        </form>
        <Block>
            <Text>
            {t('regTerms1')} <Link to={'/termsOfUse'}>{t('regTerms2')}</Link> {t('regTerms3')} <Link to={'/privacyPolicy'}>{t('regTerms4')}</Link>
            </Text>
        </Block>
    </>
  )
}

export default RegisterCollector