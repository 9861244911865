import React, { forwardRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import countriesLanguages from '@hotosm/iso-countries-languages';
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { fetchUpdateMe, selectIsAuth } from '../../../redux/slices/auth'
import axios from '../../../utils/axios'
import { useTranslation } from 'react-i18next'
import lockIcon from './../../../assets/lock-icon.svg'
import { OLD_KEYS_COUNTRY_MAP } from '../../../const/old-keys-country-map/old-keys-country-map';

const Section = styled.section`
max-width: 800px;

@media (max-width: 577px){
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
}
`

const FormBlock = styled.div`
margin-top: 1rem;
position: relative;
padding-bottom: 1rem;
padding-top: 0rem;
width: 500px;
input{
    width: 410px;
    height: 35px;
    border: 1px solid #949494;
    border-radius: 5px;
    outline: none;
    text-indent: 10px;
}

@media (max-width: 577px){
  width: 100%;
  input{
    width: 90%;
    margin-left: 1.2rem;
  }

  textarea{
    width: 90%;
  }
}
`
const Name = styled.h4`
font-size: 18px;
font-weight: 400;
@media (max-width: 577px){
  margin-left: 1.2rem;
}
`
const SubName = styled.h5`
margin-top: 0.5rem;
font-size: 14px;
font-weight: 400;
opacity: 0.8;
@media (max-width: 577px){
  margin-left: 1.2rem;
}
`
const Country = styled.div`
margin: 1rem -2rem;
display: flex;

select{
    margin-top: 5px;
    font-family: 'Montserrat';
    width: 277px;
    height: 35px;
    border: 1px solid #949494;
    border-radius: 5px;
    outline: none;
    font-size: 17px;
}

@media (max-width: 577px){
  margin-left: -0.9rem;
}
`
const CountryBlock = styled.div`
margin-left: 2rem;
@media (max-width: 577px){
.country{
    margin-left: 0rem;
  }
}
`

const HeadDest = styled.div`
  margin-top: 3rem;
  font-size: 19px;
  font-weight: 500;

  @media (max-width: 577px){
    margin-left: 20px
  }
`
const PriceDest = styled.div`
width: 282px;
margin-top: 1rem;
display: flex;
flex-direction: column;

input{
  width: 260px;
  height: 35px;
  border: 1px solid #949494;
  border-radius: 10px;
  outline: none;
  text-indent: 5px;
  margin-top: 10px;

  @media (max-width: 577px){
    margin-left: 0;
  }
}

.second{
    width: 200px
}
@media (max-width: 577px){
    margin-left: 20px
  }
`
const PriceHead = styled.div`
font-size: 17px;
font-weight: 400;
`
const PriceDesc = styled.div`
margin-top: 10px;
font-size: 13px;
font-weight: 400;
opacity: 0.8;
height: 32px;
`

const InputBlock = styled.div`
    display: grid;
    grid-template-columns: 315px 220px 50px;
    @media (max-width: 577px){
      display: block;
    }
`
const Trash = styled.div`
  margin-top: 88px;
    @media (max-width: 577px){
      display: none;
    }
`
const TrashWrapper = styled.div`
  height: 37px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  
  img {
      width: 20px;
      height: 20px;
      opacity: 0.5;
  }
`
const Btn = styled.button`
margin-top: 1rem;
width: 263px;
height: 35px;
background: #F5F5F5;
border: 1px solid #949494;
border-radius: 10px;
font-family: 'Montserrat';
@media (max-width: 577px){
  margin-left: 20px;
}
`
const Block = styled.div`
width: 800px;
position: relative;
height: 190px;
margin: 2rem 0rem;
border-bottom: 1px solid rgba(148, 148, 148, 0.5);
`

const Head = styled.div`
font-weight: 400;
font-size: 18px;

margin-top: 3rem;
`

const NamePass = styled.div`
font-size: 15px;
font-weight: 500;
`
const PassBlock = styled.div`
margin-top: 1rem;

input{
    margin-top: 1rem;
    width: 250px;
    height: 35px;
    border: 1px solid #f1f1f1;
    background: #f1f1f1;
    border-radius: 5px;
    outline: none;
    text-indent: 10px;
}
img{
    height: 25px;
    width: 20px;
    opacity: 0.5;
    position: absolute;
    margin-top: 1.4rem;
    margin-left: -1.5rem;
}
`
const BtnSubmit = styled.button`
position: relative;
width: 120px;
height: 30px;
background-color: rgba(254, 97, 3, 0.2);
color:  #000;
outline: none;
border: 0px solid #808080;
font-family: Montserrat;
font-weight: 400;
font-size: 16px;
border-radius: 10px;
cursor: pointer;
margin-left: 0rem;
margin-top: 1rem;

`

const BtnSave = styled.button`
position: relative;
width: 210px;
height: 40px;
background-color: rgba(254, 97, 3, 0.2);
color:  #000;
outline: none;
border: 0px solid #808080;
font-family: Montserrat;
font-weight: 500;
font-size: 16px;
border-radius: 10px;
cursor: pointer;
left: 0rem;

@media (max-width: 577px){
  margin-left: 1.2rem;
}
`
const Avatar = styled.div`
position: absolute;
margin-left: 34rem;
width: 210px;
font-size: 14px;
text-align: center;
margin-top: 2rem;
span {
  position: relative;
  margin-left: -35px;
  top: 0.5rem;
}
img{
  border-radius: 50%;
  width: 100px;
  height: 100px;
  position: relative;
  margin-left: 0rem;
}
@media (max-width: 577px){
  position: relative;
  margin-left: 23px;
  margin-bottom: 1rem;
}
`

const Round = styled.div`
border: 1px solid rgba(171, 154, 144, 0.7);
border-radius: 50%;
width: 97px;
height: 97px;
position: relative;
margin-left: 2.3rem;
`

const Save = styled.button`
width: 150px;
height: 40px;
background: rgba(254, 97, 3, 0.5);
border: 1px solid rgba(0, 0, 0, 0);
border-radius: 15px;
font-family: 'Montserrat';
font-size: 17px;
top: 7rem;
right: 2rem;
position: absolute;
opacity: 0.5;
z-index: 12;
transition: 0.3s ease;

&:hover{
  opacity: 1;
}
`
const HintPrice = styled.div`
position: relative;
margin: 0.5rem 1rem;
font-weight: 500;
font-size: 12px;
opacity: 0.7;

img{
  width: 12px;
  height: 12px;
  opacity: 0.5;
  margin-left: -16px;
  margin-top: 1px;
  position: absolute;
}
`

const None = styled.div`
display: none;
`

const InfoWrapper = styled.div`
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    @media (max-width: 577px){
        max-width: 380px;
    }
`

const InfoPayment = styled.div`
    width: 100%;
    
    @media (max-width: 577px){
      padding-left: 11px;
    }
`

const InfoDelivery = styled.div`
    width: 100%;

    @media (max-width: 577px){
      padding-left: 11px;
    }
`

const InfoLabel = styled.label`
    font-size: 17px;
    font-weight: 400;
    font-family: 'Montserrat';
`

const InfoTextarea = styled.textarea`
    margin-top: 10px;
    resize: none;
    width: 100%;
    border: 1px solid #949494;
    border-radius: 10px;
    height: 130px;
    outline: none;
    padding: 10px;
    font-size: 14px;

    @media (max-width: 577px){
      width: 90%;
    }
`

const TextAttributes = styled.div`
    display: flex;
    margin-top: 10px;
    input{
        width: 222px;
        height: 36px;
        border-top: 1px solid #949494;
        border-bottom: 1px solid #949494;
        border-left: 1px solid #949494;
        border-right: none;
        border-radius: 10px 0 0 10px;
        outline: none;
        text-indent: 5px;
        margin-top: 0;
        box-sizing: border-box;
    }

    select {
        font-family: 'Montserrat';
        height: 36px;
        border-left: none;
        border-radius: 0 10px 10px 0;
        outline: none;
        box-sizing: border-box;
        border-color: #949494;
    }

    .inputSecond, .selectSecond{
    }

    .inputSecond{
        width: 160px;
    }

    .selectSecond{
        margin-left: 0rem;
    }
    @media (max-width: 577px){
        input{
            width: 100%;
        }

        .inputSecond{
            width: 100%;
        }
        .selectSecond{
        }
    }
`
const PasswordBlock = styled.div`
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    
    @media (max-width: 577px){
      margin-left: 1.2rem;
    }
`

const PasswordLable = styled.label`
  font-family: Montserrat;
  font-size: 17px;
  font-weight: 400;
  line-height: 42px;
`

const InputWrapper = styled.div`
  position: relative;
  width: 410px;
  input{
    display: block;
    width: 410px;
    height: 35px;
    border: 1px solid #949494;
    border-radius: 5px;
    outline: none;
    text-indent: 10px;
    box-sizing: border-box;
  }

  img {
    display: block;
    position: absolute;
    right: 15px;
    bottom: 20%;
    cursor: pointer;
  }

  @media (max-width: 577px){
      width: 100%;
      input {
        width: 100%;
      }
    }
`

const PasswordTitle = styled.div`
    color: #000;
    font-family: Montserrat;
    font-size: 19px;
    font-weight: 500;

    @media (max-width: 577px){
     
    }
`

const Form = forwardRef(({title = " ", subname = " ", input=" ", ...formProps}, ref) => {
  return(
      <FormBlock>
          <Name>{title}</Name>
          <input ref={ref}  type="text" size="0" {...formProps} />
          <SubName>{subname}</SubName>
      </FormBlock>
  )
})

const FirstParams = () => {

  const dispatch = useDispatch()
  const authInfo = useSelector(selectIsAuth)
  const [avatarUrl, setAvatarUrl] = React.useState(null)
  const [isShown, setIsShown] = React.useState(false)
  const inputFileRefAvatar = React.useRef('')
  const [isLoading, setLoading] = React.useState('')
  const [oldPass, setOldPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [isShowOldPass, setIsShowOldPass] = useState(false);
  const [isShowNewPass, setIsShowNewPass] = useState(false);
  const { t, i18n } = useTranslation();

  const countriesList = countriesLanguages.getCountries(i18n.language);

  const handleChangeAvatar = async (event) => {

    try {
      const formData = new FormData();
      const files = event.target.files[0]
      formData.append('avatar', files);
      const { data } = await axios.post('/upload/avatar', formData)
      setAvatarUrl(data.url)
      setIsShown(!isShown)
      console.log(avatarUrl)
  } catch (err) {
      console.warn(err);
      alert('Ошибка при загрузке изображения')
    }
  }
 
  const { register, handleSubmit, getValues, setValue, watch } = useForm({
    defaultValues:{
      shopname: authInfo.shopname,
      shortlink: authInfo.shortlink,
      surname: authInfo.surname,
      username: authInfo.username,
      email: authInfo.email,
      fbUrl: authInfo.fbUrl,
      tgUrl: authInfo.tgUrl,
      instUrl: authInfo.instUrl,
      vkUrl: authInfo.vkUrl,
      country: OLD_KEYS_COUNTRY_MAP[authInfo.country] || authInfo.country,
      paymentDescription: authInfo.paymentDescription,
      deliveryDescription: authInfo.deliveryDescription,
      destPrice: authInfo.delivery[0].destPrice,
      destCurrency: authInfo.delivery[0].destCurrency,
      destination: authInfo.delivery[0].destination,
    },
    mode: 'onChange'
  })

  const onSubmit = async (values) => {
    const data = await dispatch(fetchUpdateMe(values))

    if (authInfo.shortlink !== getValues('shortlink')) {
      await axios.patch('/auth/me/shortlink', {
        shortlink: getValues('shortlink').replace(/\s/g, '')
      });
    }

    if (oldPass && newPass) {
      await sendPass();
    }

    if (!data.payload) {
      return alert('Не удалось авторизоваться')
    }
    window.location.reload();
  }

  const onSubmitAvatar  = async () => {
    try {
        setLoading(true)

        const fields ={
            avatarUrl,
        }

        const { data } = await axios.patch('/auth/me', fields)

        const id = data._id

        window.location.reload()
    } catch (err) {
        console.warn(err)
        alert('Ошибка при загрузке товара')
    }
}

  const handleDeliveryAdd = () => {
    setValue('destination', [...getValues('destination'), '']);
    setValue('destCurrency', [...getValues('destCurrency'), 'USD']);
    setValue('destPrice', [...getValues('destPrice'), '']);
  };

  const handleDeliveryDelete = (indexToRemove) => {
    setValue('destination', getValues('destination').filter((_, i) => i !== indexToRemove));
    setValue('destCurrency', getValues('destCurrency').filter((_, i) => i !== indexToRemove));
    setValue('destPrice', getValues('destPrice').filter((_, i) => i !== indexToRemove));
  }

  const handleDeliveryChange = (name, index, nextValue) => {
    setValue(name, getValues(name).map((prevValue, i) => i === index ? nextValue : prevValue));
  }

  const sendPass = async () => {
    await axios.patch('/auth/me/password ', {oldPassword: oldPass, newPassword: newPass}).then(() => {
      alert(t('passWasChanged'))
    })
    .catch((data) => {
      const res = data.response.data.message;
      alert(res);
    })
  }


  return (
    <>
    <Section>

      <input ref={inputFileRefAvatar} name='avatar' type='file' onChange={handleChangeAvatar} encType="multipart/form-data"  hidden/>
      
      {avatarUrl === null ? 
      <Avatar onClick={() => inputFileRefAvatar.current.click()}>        
      <Round />
      <span>{t('avatar')}</span>
      </Avatar> 
      :
      <Avatar>
      <img className='image2' src={`https://toobears.com/api/${avatarUrl}`} alt='uploaded' />
      {isShown && <Save onClick={onSubmitAvatar}>Сохранить</Save>}
      </Avatar>
      }

      <form onSubmit={handleSubmit(onSubmit)}>
      <FormBlock>
        <Name>{t('shopname')}</Name>
        <input type="text" {...register('shopname', { required: 'Укажите название магазина'})} />
        <SubName>{t('shopNameText')}</SubName>
      </FormBlock>
      <FormBlock>
        <Name>{t('shortlink')}</Name>
        <input type="text" {...register('shortlink', { required: 'Укажите название ссылки'})} />
      </FormBlock>
      <FormBlock>
        <Name>{t('settingsName')}</Name>
        <input type="text" {...register('username', { required: 'Укажите имя'})} />
      </FormBlock>
      <FormBlock>
        <Name>{t('settingsSurname')}</Name>
        <input type="text" {...register('surname', { required: 'Укажите фамилию'})} />
      </FormBlock>
      <Form title="Email" subname=" "  {...register('email')}/>
      <Country>
        <CountryBlock>
          <Name className='country' {...register('country')}>
            {t('country')}
          </Name>
          <select {...register('country')}>
            {Object.entries(countriesList).map(([k, v]) => (
              <option value={k} key={k}>{k === 'BY' && i18n.language === 'ru' ? 'Беларусь' : v}</option>
            ))}
          </select>
        </CountryBlock>
      </Country>
      <PasswordBlock>
        <PasswordTitle>
          {t('changePass')}
        </PasswordTitle>
        <PasswordLable>
          {t('prevPass')}
          <InputWrapper>
              <input type={isShowOldPass ? "text" : "password"}  value={oldPass} onChange={(e) => {setOldPass(e.currentTarget.value)}}/>
              <img alt='lock' onClick={() => setIsShowOldPass(!isShowOldPass)} src={lockIcon} />
          </InputWrapper>
        </PasswordLable>
        <PasswordLable>
          {t('newPass')}
          <InputWrapper>
              <input minLength={6} type={isShowNewPass ? "text" : "password"} value={newPass} onChange={(e) => setNewPass(e.currentTarget.value)}/>
              <img alt='lock' onClick={() => setIsShowNewPass(!isShowNewPass)} src={lockIcon}  />
          </InputWrapper>
        </PasswordLable>
      </PasswordBlock>
      <FormBlock>
        <HeadDest>
            {t('destination')}
            <HintPrice><img src='https://svgshare.com/i/jcz.svg' alt='hide' /> {t('tooltip1')} <br/> {t('tooltip2')}</HintPrice>
        </HeadDest>
        <InputBlock>
            <PriceDest>
                <PriceHead>
                {t('Add country')}
                </PriceHead>
                <PriceDesc>
                  {t('Add the destination country or write Worldwide for worldwide delivery')}
                </PriceDesc>
                {watch('destination').map((v, index) =>
                  <input
                    required
                    type="text"
                    size="2"
                    key={index}
                    value={v}
                    onChange={({ currentTarget }) => handleDeliveryChange('destination', index, currentTarget.value)}
                  />
                )}
            </PriceDest>
            <PriceDest>
                <PriceHead>
                {t('destPrice')}
                </PriceHead>
                <PriceDesc>
                {t('destPriceTooltip')}

                </PriceDesc>
                {watch('destination').map((c, index) => (
                    <TextAttributes key={index}>
                        <input
                          required
                          className='inputSecond'
                          type="number"
                          size="2"
                          value={watch('destPrice')[index]}
                          onChange={({ currentTarget }) => handleDeliveryChange('destPrice', index, currentTarget.value)}
                        />
                        <select
                          required
                          className='selectSecond'
                          value={watch('destCurrency')[index]}
                          onChange={({ currentTarget }) => handleDeliveryChange('destCurrency', index, currentTarget.value)}
                        >
                            <option value={'USD'}>USD</option>
                            <option value={'RUB'}>RUB</option>
                            <option value={'EUR'}>EUR</option>
                        </select>
                    </TextAttributes>
                ))}
            </PriceDest>
            <Trash>
                {watch('destination').map((v, i) => {
                    return  (
                      <TrashWrapper key={i}>
                        <img src="https://svgshare.com/i/iEC.svg" onClick={() => handleDeliveryDelete(i)} alt="trash" />
                      </TrashWrapper>
                    )
                })}
            </Trash>
        </InputBlock>
        <Btn onClick={handleDeliveryAdd} type='button'>
            {t('addCountry')}
        </Btn>
      </FormBlock>
      <FormBlock>
        <InfoWrapper>
              <InfoPayment>
                  <InfoLabel>
                      <div>{t('paymentInfo')}</div>
                      <InfoTextarea
                        {...register('paymentDescription')}
                        // value={paymentDecsription}  onChange={(e) => setPaymentDecsription(e.target.value)} 
                      />
                  </InfoLabel>
              </InfoPayment>
              <InfoDelivery>
                  <InfoLabel>
                      <div>{t('deliveryInfo')}</div>
                      <InfoTextarea 
                        {...register('deliveryDescription')}
                        // value={deliveryDecsription}  onChange={(e) => setDeliveryDecsription(e.target.value)} 
                        />
                  </InfoLabel>
              </InfoDelivery>
        </InfoWrapper>
      </FormBlock>
    <None>
    <HeadDest>
        {t('deliveryPrice')}
            <HintPrice><img src='https://svgshare.com/i/jcz.svg' /> {t('delCountryTooltip')}</HintPrice>
        </HeadDest>
        <InputBlock>
        <PriceDest>
            <PriceHead>
            {t('destination')}
            </PriceHead>
            <PriceDesc>
            {t('destTooltip')}
                
            </PriceDesc>
            <input type="text" size="2"/>
            <input type="text" size="2"/>
        </PriceDest>
        <PriceDest>
            <PriceHead>
            {t('destPrice')}
            </PriceHead>
            <PriceDesc>
                {t('destPriceTooltip')}
                <br />
                <br />

            </PriceDesc>
            <input type="text" size="1" className="second" />
            <input type="text" size="1" className="second"/>
        </PriceDest>
        <Trash>
        <img src="https://svgshare.com/i/iEC.svg" alt="trash" />
        <img src="https://svgshare.com/i/iEC.svg" alt="trash" />
        </Trash>
        </InputBlock>
        <Btn>
          {t('addCountry')}
        </Btn>
        <Block>
        <Head>
        {t('chngPass')}
        </Head>
        <PassBlock>
            <NamePass>
            {t('password')}
            </NamePass>
            <input type="text" size="0" /><img src='https://svgshare.com/i/iKc.svg' title='lock' />
        </PassBlock>
        <BtnSubmit>
            <span>{t('chng')}</span>
        </BtnSubmit> 
    </Block>
    </None> 
    <BtnSave type="submit">
        {t('save')}
    </BtnSave>
    </form>
    </Section>
    </>
  )
}

export default FirstParams