import { useTranslation } from 'react-i18next'
import {Root, BannerBear, MainText, MainTextTitle, ShowListTitle, ShowList, ShowListItem, ShowListSmall, OurBenefits, ShowListBenefitItem, ShowByeTitle,
    OnlineShowTitle,  OnlineShowSubTitle, ShowListItemGreen,
    ShowListTitleBeige,
    WinnersTitle,
    TestGradText,
    WinnersTitleMobile,
    WinnersIcon,
    TestGradTextSecond
} from './styles';
import { MarketPart } from './online-show-works/styles';
import winIcon from './online-show-finish/assets/win-icon.png'
import naturalWinner from './online-show-finish/assets/fox-winner.png'
import adorableWinner from './online-show-finish/assets/adorable-winner.png'
import originalWinner from './online-show-finish/assets/original-winner.png'
import creativeWinner from './online-show-finish/assets/creative-winner.png'
import choiceWinner from './online-show-finish/assets/choice-winner.png'
import WinnerCard from './online-show-finish/winner-card/winner-card';

const OnlineShow = () => {
    const { t } = useTranslation();

    return (
        <Root>
            <BannerBear></BannerBear>
            <OnlineShowTitle>{t('OnlineShowTitle')}</OnlineShowTitle>
            <OnlineShowSubTitle>{t('OnlineShowSubTitle')}</OnlineShowSubTitle>
            <MainText>
                <MainTextTitle><i>{t('ToobearShow')} </i>- </MainTextTitle>
                {t('ToobearShowDesc')}
            </MainText>
            <MainText>{t('ShowDesc2')}</MainText>
            <ShowListTitle><i>{t('ShowRules')}</i></ShowListTitle>
            <ShowList>
                <ShowListItem><i><b>{t('ShowItem1/2')}</b></i>{t('ShowItem2/2')}</ShowListItem>
                <ShowListItem>{t('ShowItem2-1/2')}<ShowListItemGreen><i><b>{t('ShowItem2-2/2')}</b></i></ShowListItemGreen></ShowListItem>
                <ShowListItem>{t('ShowItem3')}</ShowListItem>
                <ShowListItem>{t('ShowItem4')}</ShowListItem>
                <ShowListItem>{t('ShowItemPrice')}</ShowListItem>
                <ShowListItem><b><i>{t('ShowItem5-1/2')}</i>{t('ShowItem5-2/2')}</b></ShowListItem>
                <ShowListItem>{t('ShowItem6/1')}</ShowListItem>
                <ShowListItem>{t('ShowItem6')}</ShowListItem>
                <ShowListItem>{t('ShowItem8')}</ShowListItem>
                <ShowListItem>{t('ShowItem9-1/2')}<b><i>{t('ShowItem9-2/2')}</i></b></ShowListItem>
                <ShowListSmall>
                    <ShowListItem>{t('ShowItemSmall1')}</ShowListItem>
                    <ShowListItem>{t('ShowItemSmall2')}</ShowListItem>
                    <ShowListItem>{t('ShowItemSmall3')}</ShowListItem>
                    <ShowListItem>{t('ShowItemSmall4')}</ShowListItem>
                    <ShowListItem>{t('ShowItemSmall5')}</ShowListItem>
                </ShowListSmall>
                <ShowListItem>{t('ShowItem10-1/3')}<b><i>{t('ShowItem10-2/3')}</i></b>{t('ShowItem10-3/3')}</ShowListItem>
                <ShowListItem>{t('ShowItem11')}</ShowListItem>
            </ShowList>
            <WinnersTitle>
                <TestGradText>
                    {t('WinnerShowTitle1')}<br/>
                    <TestGradTextSecond>{t('WinnerShowTitle2')}</TestGradTextSecond>
                </TestGradText>
            </WinnersTitle>
            <WinnersTitleMobile>
                <TestGradText>{t('WinnerShowTitleMobile1')}</TestGradText>
                <br/><TestGradTextSecond>{t('WinnerShowTitleMobile2')}</TestGradTextSecond>
            </WinnersTitleMobile>
                <MarketPart>
                    <WinnerCard
                        nomination={<>The most natural <WinnersIcon alt='win' src={winIcon}/> <br/>look</>}
                        img={naturalWinner}
                        name="Miniature fox Elly"
                        author="Svetlana Krantova"
                        authorLink="/krantovatoys"
                        cardLink="/card/66696f3b4148833efef82b15"
                        nominationMobile={<>The most natural look <WinnersIcon alt='win' src={winIcon}/></>}
                    />
                    <WinnerCard
                        nomination={<>The most adorable <WinnersIcon alt='win' src={winIcon}/> <br/>bear of the show</>}
                        img={adorableWinner}
                        name="Mule"
                        author="Anastasiya Ulaysi"
                        authorLink="/Fabulous_toys"
                        cardLink="/card/6669752e4148833efef83a37"
                        nominationMobile={<>The most adorable bear of the show <WinnersIcon alt='win' src={winIcon}/></>}
                    />
                    <WinnerCard
                        nomination={<>The most original <WinnersIcon alt='win' src={winIcon}/> <br/>composition</>}
                        img={originalWinner}
                        name="The forest fairy Leya"
                        author="Danata Tselinskaya"
                        authorLink="/danatadolls"
                        cardLink="/card/6669836d4148833efef892c7"
                        nominationMobile={<>The most original composition <WinnersIcon alt='win' src={winIcon}/></>}
                    />
                    <WinnerCard
                        nomination={<>The most creative look <WinnersIcon alt='win' src={winIcon}/></>}
                        img={creativeWinner}
                        name="Forest Fairy Butterfly"
                        author="Marina Golovacheva"
                        authorLink="/ragdollshopart"
                        cardLink="/card/666979074148833efef86de8"
                        nominationMobile={<>The most creative look <WinnersIcon alt='win' src={winIcon}/></>}
                    />
                    <WinnerCard
                        nomination={<>Toobears Choise <WinnersIcon alt='win' src={winIcon}/></>}
                        img={choiceWinner}
                        name="Keeper of Forest Stories"
                        author="Irina Drozdova"
                        authorLink="/6525243e377136f2e27e1be9"
                        cardLink="/card/66697bb14148833efef87c8e"
                        nominationMobile={<>Toobears Choise <WinnersIcon alt='win' src={winIcon}/></>}
                    />
                </MarketPart>
            <OurBenefits>
                <ShowListTitleBeige><i>{t('OurBenefits')}</i></ShowListTitleBeige>
                <ShowListSmall>
                    <ShowListBenefitItem>{t('OurBenefitsItem1')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('OurBenefitsItem2')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('OurBenefitsItem3')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('OurBenefitsItem4')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('OurBenefitsItem5')}</ShowListBenefitItem>
                </ShowListSmall>
            </OurBenefits>
            <ShowByeTitle>
                {t('ByeShoe')}
            </ShowByeTitle>
        </Root>
    )
}

export default OnlineShow;