import React, { useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import {DndContext, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors} from '@dnd-kit/core';
import {SortableContext, arrayMove} from '@dnd-kit/sortable';
import loadImage from 'blueimp-load-image';

import img from '../../../assets/camera.png'
import {  useSelector } from 'react-redux'
import axios from '../../../utils/axios'
import { selectIsAuth } from '../../../redux/slices/auth'
import { useNavigate, Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ImageWrapper from './Image-Wrapper';
import { dataURLtoFile } from '../../../utils/base64ToDataUrl';
import Toast from '../../../ui-kit/toast/toast';
import CustomRulesTextField from '../../../ui-kit/custom-rules-text-field/custom-rules-text-field';
import { ErrorText, Input, Textarea } from '../../../ui-kit/custom-rules-text-field/styles';
import { ToastWrapper } from '../../../ui-kit/toast/styles';

const Section = styled.section`
width: 610px;
position: relative;
margin-left: 50px;

@media (max-width: 577px){
    margin-left: 0;
    width: auto;
    ${ToastWrapper} {
        margin-left: 1.2rem;
    }
}
`
const Head = styled.div`
width: 610px;
padding-bottom: 10px;
border-bottom: 1px solid rgba(148, 148, 148, 0.5) ;
font-family: 'Palatino';
font-weight: 400;
font-size: 26px;
margin-bottom: 20px;

@media (max-width: 577px){
    width: 380px;
    margin-left: 1.2rem;
    width: auto;
}
`
const AddImg = styled.button`
width: 220px;
height: 270px;
display: flex;
flex-direction: column;
flex-wrap: wrap;
border: 2px dashed rgba(57, 105, 176, 0.33);
border-radius: 20px;
justify-content: center;
align-items: center;
margin-top: 25px;
font-family: 'Montserrat';
background: rgba(57, 105, 176, 0.13);

@media (max-width: 577px){
    margin: 10px auto 0;
}
`
const ImgCont = styled.div`
img{
    width: 66px;
    height: 63px;
}

`
const TextDesc = styled.div`
text-align: center;
font-size: 11px;
`
const HeadName = styled.div`
font-size: 19px;
font-weight: 500;
color: ${props => props.errorTitle && '#FB0808'};
@media (max-width: 577px){
    margin-left: 1.2rem;
}
`
const FormName = styled.div`
    margin-top: 0.5rem;

    ${Input} {
        width: 562px;
    }

    @media (max-width: 577px){
        ${Input} {
            max-width: 360px;
            width: 100%;
            margin-left: 1.2rem;
        }
        ${ErrorText} {
            margin-left: 1.2rem;
        }
    }
`
const Desc = styled.div`
    margin-top: 0.5rem;
    box-sizing: border-box;
    ${Textarea} {
            width: 562px;
            height: 250px;
        }

    @media (max-width: 577px){
        ${Textarea}{
            width: 91%;
            height: 170px;
            margin-left: 1.2rem;
        }
    }
`
const HeadDest = styled.div`
font-size: 19px;
font-weight: 500;
color: ${props => props.errorTitle && '#FB0808'};

@media (max-width: 577px){
    margin-left: 1.2rem;
}
`
const PriceDest = styled.div`
    width: 282px;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;

    ${Input} {
        width: 260px;
        height: 35px;
        margin-top: 15px;
    }

    .second{
        width: 200px;
    }
    @media (max-width: 577px){
        margin-top: 0;
        width: 150px;
        margin-right: 5px;

        ${Input} {
            width: 150px;
            margin-top: 17px;
        }
    }
`
const PriceHead = styled.div`
    font-size: 17px;
    font-weight: 400;
    color: ${props => props.errorTitle && '#FB0808'};
    @media (max-width: 577px){
        white-space: nowrap;
    }
`
const PriceDesc = styled.div`
    margin-top: 10px;
    font-size: 13px;
    font-weight: 400;
    opacity: 0.8;
    height: 32px;
    @media (max-width: 577px){
        font-size: 10px;
    }
`
const InputBlock = styled.div`
    display: grid;
    grid-template-columns: 315px 230px 35px;

    @media (max-width: 577px){
        display: flex;
        margin-left: 1.2rem;
        max-width: 360px;
    }
`
const Trash = styled.div`
    margin-top: 0px;
    img{
        width: 25px;
        height: 25px;
        opacity: 0.5;
    }

    @media (max-width: 577px){
        margin-top: 0px;
    }
`
const Btn = styled.button`
margin-top: 1rem;
width: 263px;
height: 35px;
background: #F5F5F5;
border: 1px solid #949494;
border-radius: 10px;
font-family: 'Montserrat';

@media (max-width: 577px){
    margin-left: 1.2rem;
    margin-top: 1.5rem;
}
`
const Check = styled.div`
margin-top: 1rem;

input{
    position: absolute;
    z-index: -1;
    opacity: 0;
}
input+label{
    display: inline-flex;
    align-items: center;
    user-select: none;
}
input+label::before{
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #949494;
    border-radius: 0.25em;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}
input:not(:disabled):not(:checked)+label:hover::before {
    border-color: rgba(254, 97, 3, 0.2);
}
input:not(:disabled):active+label::before{
    background-color: #EAE2DA;
    border-color: #EAE2DA;
}
input:focus:not(:checked)+label::before{
    border-color: #EAE2DA;
}
input:checked+label::before{
    border-color: #949494;
    background-color: rgba(254, 97, 3, 0.2);
    background-image: url(https://svgshare.com/i/iBL.svg);
}
input:disabled+label::before{
    background-color: rgba(254, 97, 3, 0.2);
}
`
const Buttn = styled.button`
position: relative;
margin-top: 3rem;
width: 235px;
height: 56px;
background: rgba(254, 97, 3, 0.2);
border: 1px solid rgba(0, 0, 0, 0.2);
border-radius: 10px;
font-family: 'Montserrat';
font-size: 17px;
cursor: pointer;

@media (max-width: 577px){
    margin-left: 1.2rem;
}
`
const FormHead = styled.section`
margin-top: 2rem;

@media (max-width: 577px){
    max-width: 380px;
}
`
const HeadAttributes = styled.div`
font-size: 17px;
font-weight: medium;
`
const TextAttributes = styled.div`
display: flex;
    margin-top: 15px;
    input{
        width: 222px;
        height: 36px;
        border-top: 1px solid #949494;
        border-bottom: 1px solid #949494;
        border-left: 1px solid #949494;
        border-right: none;
        border-radius: 10px 0 0 10px;
        outline: none;
        text-indent: 5px;
        margin-top: 0;
        box-sizing: border-box;
    }

    select {
        font-family: 'Montserrat';
        height: 36px;
        border-left: none;
        border-radius: 0 10px 10px 0;
        outline: none;
        box-sizing: border-box;
        border-color: #949494;
    }

    .inputSecond, .selectSecond{
    }

    .inputSecond{
        width: 160px;
    }

    .selectSecond{
        margin-left: 0rem;
    }
    @media (max-width: 577px){
        margin-top: 17px;
        input{
            width: 100%;
        }

        .inputSecond{
            width: 100%;
        }
        .selectSecond{
        }
    }
`
const AttributesDropDownFirst = styled.div`
display: flex;

@media (max-width: 577px) {
    flex-direction: column;
    margin-left: 1.2rem;
}
`
const Size = styled.div`
margin-left: 1rem;

@media (max-width: 577px){
    margin-left: 0rem;
    margin-top: 1rem;
}
`
const Price = styled.div`

`
const AttributesDropDownSecond = styled.div`
margin-top: 1rem;
display: flex;

select{

}

@media (max-width: 577px) {
    flex-direction: column;
    margin-left: 1.2rem;
}
`
const DropAttributes = styled.div`
margin-top: 10px;

select{
    font-family: 'Montserrat';
    width: 277px;
    height: 40px;
    border: 1px solid #949494;
    border-radius: 10px;
    outline: none;
    font-size: 17px;
}

.status{
    width: 281px;
}

@media (max-width: 577px){
    select, .status{
        width: 100%;
    }
}
`
const Images = styled.div`
    margin-top: 10px;
    width: fit-content;
    height: fit-content;
    border-radius: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;

    @media (max-width: 577px){
        grid-template-columns: 1fr 1fr;
        margin: 10px auto 0;
        gap: 20px;
    }
`
const HintPrice = styled.div`
position: relative;
margin: 0.5rem 1rem;
font-weight: 500;
font-size: 12px;
opacity: 0.7;

img{
  width: 12px;
  height: 12px;
  opacity: 0.5;
  margin-left: -16px;
  margin-top: 9px;
  position: absolute;
}
`
const FlexBox = styled.div`
@media (max-width: 577px){
    margin-left: 0rem;
    width: 100%;
    display: flex;
    flex-direction: column;
}
`
const InputBox = styled.div`
@media (max-width: 577px){
    width: 100%;
    overflow-x: hidden;
}
`
const InfoWrapper = styled.div`
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    @media (max-width: 577px){
        max-width: 380px;
        margin-left: 1.2rem;
    }
`
const InfoPayment = styled.div`
    width: 100%;

`
const InfoDelivery = styled.div`
    width: 100%;

`
const InfoLabel = styled.label`
    font-size: 17px;
    font-weight: 400;
    font-family: 'Montserrat';

    ${Textarea} {
        margin-top: 10px;
        width: 100%;
        height: 130px;
        padding: 10px;
        font-size: 14px;

        @media (max-width: 577px){
            max-width: 300px;
        }

        @media (max-width: 350px){
            max-width: 280px;
        }
    }
`

const InfoTitle = styled.div`
    font-size: 17px;
    line-height: 42px;
    font-weight: 500;
    color: ${props => props.errorTitle && '#FB0808'};

    @media (max-width: 414px){
        font-size: 15px;
    }

`
const TrashWrapper = styled.div`
  height: 37px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
  
  img {
      width: 20px;
      height: 20px;
      opacity: 0.5;
  }
`
const CheckboxContainer = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: center;
    box-sizing: border-box;

    label {
        font-size: 18px;
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    label::before {
        content: "";
        border: 1px solid black;
        height: 30px;
        width: 30px;
        display: block;
        border-radius: 2px;
        margin-right: 8px;
        box-sizing: border-box;
        flex: 0 0 auto;
    }

    input {
        cursor: pointer;
        opacity: 0;
        position: absolute;
    }

    input:checked {
        &+label::before {
            content: '\\002714';
            background-color: #FFE7D9;
            text-align: center;
            border: none;
            font-size: 22px;
            opacity: 0.8;
        }
    }

    @media (max-width: 414px){
        margin-left: 1.2rem;

        label {
            font-size: 13px;
        }

        label::before {
            height: 20px;
            width: 20px;
        }

        input:checked {
            &+label::before {
                font-size: 16px;
            }
        }
    }
`

const Add = (res) => {
    const isAuth = useSelector(selectIsAuth)

    const navigate = useNavigate()

    const inputFileRef = React.useRef(null)

    const [clientImages, setClientImages] = React.useState([]);
    const [hashtags, setHashtags] = React.useState('')
    const [cathegory, setCathegory] = React.useState('')
    const [price, setPrice] = React.useState(0)
    const [size, setSize] = React.useState(0)
    const [currency, setCurrency] = React.useState('USD')
    const [status, setStatus] = React.useState('1')
    const [showpageDisplayed, setShowpageDisplayed] = React.useState(false);
    const DEFAULT_FORM_FIELDS = {
        name: '',
        description: '',
        deliveryDescription: isAuth.deliveryDescription,
        paymentDescription: isAuth.paymentDescription,
        destination: isAuth.delivery[0].destination.length ? isAuth.delivery[0].destination : [''],
        destPrice: isAuth.delivery[0].destPrice.length ? isAuth.delivery[0].destPrice : ['0'],
        destCurrency: isAuth.delivery[0].destCurrency.length ? isAuth.delivery[0].destCurrency : ['USD']
    }
    const [formFields, setFormFields] = useState(DEFAULT_FORM_FIELDS);
    const [errors, setErrors] = useState({
        destination: (isAuth.delivery[0]?.destination || []).map(() => null)
    });
    const isDestinationInvalid = useMemo(() => errors.destination.some((err) => Boolean(err)), [errors])
    const isFormInvalid = useMemo(() => Boolean(errors.name) || Boolean(errors.description) || Boolean(errors.deliveryDescription) || Boolean(errors.paymentDescription), [errors]);

    const { t } = useTranslation();
    
    const Rules =  [{ regex: /^[a-zA-Z0-9 ’№,.:;?!#$%&()+-_*|'s/(\d{5})/\r\n\1/gs]+$/, errorText: t('OnlyEnglishRule') }]
    const [isError, setIsError] = React.useState(false);
    const inputChangeHandler = (value, e) => {
        setFormFields((prev) => ({
            ...prev,
            [e.target.name]: value
        }));
        setErrors((prev) => ({
            ...prev,
            [e.target.name]: null
        }));
        setIsError(false)
    }
    
    const inputErrorHandler = (err, value, e) => {
        setFormFields(
            (prev) => ({
                ...prev,
                [e.target.name]: value
            })
        );
        setErrors((prev) => ({
            ...prev,
            [e.target.name]: err
        }));
        setIsError(true)
    }

    const destinationInputChangeHandler = (value, e) => {
        setFormFields((prev) => ({
            ...prev,
            destination: prev.destination.map((v, i) => i.toString() === e.target.name ? value : v)
        }));
        setErrors((prev) => ({
            ...prev,
            destination: prev.destination.map((v, i) => i.toString() === e.target.name ? null : v)
        }));
        setIsError(false)
    }
    
    const destinationInputErrorHandler = (err, value, e) => {
        setFormFields((prev) => ({
            ...prev,
            destination: prev.destination.map((v, i) => i.toString() === e.target.name ? value : v)
        }));
        setErrors((prev) => ({
            ...prev,
            destination: prev.destination.map((v, i) => i.toString() === e.target.name ? err : v)
        }));
        setIsError(true)
    }
    

    const mouseSensor = useSensor(MouseSensor, {
        activationConstraint: {
          distance: 1,
        },
      });
    const mobileSensor = useSensor(TouchSensor, {
        activationConstraint: {
            distance: 5
        }
    });
    const keyboardSensor = useSensor(KeyboardSensor)
    const sensors = useSensors(mouseSensor, keyboardSensor, mobileSensor)

    const handleChangeFile = async (event) => {
        const uploads = await Promise.all([...event.target.files].map((file) => loadImage(
            file,
            { orientation: true, canvas: true, maxWidth: 1920, maxHeight: 1080, imageSmoothingQuality: 'high' }
        )))

        setClientImages((prev) => ([
            ...prev,
            ...uploads.map(({ image }, index) => ({
                id: `${event.target.files[index].name}_${event.target.files[index].size}_${Math.random()}`,
                name: event.target.files[index].name,
                base64: image.toDataURL(`image/jpeg`)
            }))
        ]))
    }

    const imageRotateHandler = async (imageId, orientation) => {
        const { base64 } = clientImages.find(({ id }) => id === imageId);

        const nextImage = await loadImage(
            base64,
            { orientation, canvas: true, maxWidth: 700, maxHeight: 800 }
        )

        setClientImages((prev) => prev.map((img) =>
            img.id === imageId
                ? {
                    ...img,
                    base64: nextImage.image.toDataURL(`image/jpeg`)
                }
                : img
        ));
    }

    const deleteImg = (idForDelete) => {
        setClientImages((prev) => prev.filter(({ id }) => id !== idForDelete))
    }

    const onSubmit = async () => {
        if (isFormInvalid || isDestinationInvalid) return;
        switch (true) {
            case !clientImages.length:
                alert(t('addPhoto'));
                return;
            case !cathegory:
                alert(t('chooseCathegory'))
                return;
            case price === 0:
            case size === 0:
            case !formFields.name:
            case !formFields.description:
            case formFields.destination.some((v) => !v):
            case formFields.destPrice.some((v) => !v):
            case !formFields.paymentDescription || !formFields.deliveryDescription:
                alert(t('fullAllFields'));
                return;
            default:
                break;
                
        }
        
        try {
            const formData = new FormData();

            clientImages.forEach(({ base64, name }) => {
                formData.append('images', dataURLtoFile(base64, name))
            })
            const imgUrlList = await axios.post('/upload', formData)
            const fields = {
                name: formFields.name,
                price,
                size,
                description: formFields.description,
                imgUrl: imgUrlList.data?.urls || [],
                hashtags,
                cathegory,
                currency,
                destination: formFields.destination,
                destPrice: formFields.destPrice,
                status,
                destCurrency: formFields.destCurrency,
                deliveryDescription: formFields.deliveryDescription,
                paymentDescription: formFields.paymentDescription,
                showpageDisplayed,
                showpageApproved: false
            }

            await axios.post('/market', fields);
            navigate('/account/CheckWork');

        } catch (err) {
            if (err.response.status == 425) {
                alert(t('OnlyOnePerDayRule'))
                return
            }
            alert("Ошибка во время добавления работы")
        }
    }

    if (window.localStorage.getItem('token') && !isAuth) {
        return <Navigate to='/' />
    }

    const handleClick = () => {
        setFormFields((prev) => (
            {
                ...prev,
                destination: [
                    ...prev.destination,
                    ''
                ],
                destPrice: [
                    ...prev.destPrice,
                    ''
                ],
                destCurrency: [
                    ...prev.destCurrency,
                    'USD'
                ],
            }
        ))
      };

    const handleClickDelete = (index) =>{
        setFormFields((prev) => (
            {
                ...prev,
                destination: prev.destination.filter((v, i) => i !== index),
                destPrice: prev.destPrice.filter((v, i) => i !== index),
                destCurrency: prev.destCurrency.filter((v, i) => i !== index),
            }
        ))
    }

    function handleDragEnd(event) {
        const { active, over } = event;

        if (active && over && active.id !== over.id) {
            setClientImages((items) => {
                const oldIndex = items.findIndex(({ id }) => id === active?.id);
                const newIndex = items.findIndex(({ id }) => id === over?.id);
        
                return arrayMove(items, oldIndex, newIndex);
            });
        }
      }

      
  return (
    <Section>
        <Head>
            {t('addWork')}
        </Head>
        <Toast description={t('OneTimePerDayNF')} isOpenNotification />
        <Toast isOpenNotification={isError} isRed  description={t('errorTextAddWork')} withoutClose marginTop/>
        <AddImg onClick={() => inputFileRef.current.click()}>
            <ImgCont >
                <img src={img} alt='camera' />
            </ImgCont>
            <TextDesc>
            {t('mainPhoto2')}
            </TextDesc>
        </AddImg>

        <input
            ref={inputFileRef}
            name='images'
            multiple='multiple'
            type='file'
            onChange={handleChangeFile}
            encType="multipart/form-data"
            hidden
        />
        <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
            {clientImages.length === 0 ?
                <div className='textAdd'>
                    {t('upload')}
                </div>
                :
                <Images>
                    <SortableContext items={clientImages}>
                        {clientImages.map((image, index) => (
                            <ImageWrapper
                                deleteImg={deleteImg}
                                key={image.id}
                                id={image.id}
                                isMain={index === 0}
                                img={image.base64}
                                onImageRotate={imageRotateHandler}
                            />
                        ))}
                    </SortableContext>
                </Images>
            }
        </DndContext>
        <FlexBox>
        <FormHead>
        <HeadName errorTitle={errors.name}>
            {t('workName')}
        </HeadName>
        <FormName>
            <CustomRulesTextField value={formFields.name} name="name" onChange={inputChangeHandler} onError={inputErrorHandler} rules={Rules} />
        </FormName>
        </FormHead>
        <FormHead>
        <AttributesDropDownFirst>
        <Price>
        <HeadAttributes>
        {t('price')}
        </HeadAttributes>
        <TextAttributes>
            <input  type="number" size="2" value={price} onChange={(e) => setPrice(e.target.value.length ? Number(e.target.value) : '')}  />
            <select value={currency} onChange={(e) => {setCurrency(e.target.value || 'USD')}}>
                <option value='USD'>USD</option>
                <option value='RUB'>RUB</option>
                <option value='EUR'>EUR</option>
            </select>
        </TextAttributes>
        </Price>
        <Size>
        <HeadAttributes>
        {t('size')}
        </HeadAttributes>
        <TextAttributes>
            <input  type="number" size="2" className="second" value={size} onChange={(e) => setSize(e.target.value.length ? Number(e.target.value) : '')} />
            <select>
                <option>CM</option>
                <option>INCH</option>
            </select>
        </TextAttributes>
        </Size>
        </AttributesDropDownFirst>
        <AttributesDropDownSecond>
        <Price>
        <HeadAttributes>
        {t('cathegory')}
        </HeadAttributes>
        <DropAttributes>
            <select value={cathegory} onChange={(e) => setCathegory(e.target.value)}>
                <option>{t('select')}</option>
                <option value="teddy">{t('teddy')}</option>
                <option value="doll">{t('doll')}</option>
            </select>
        </DropAttributes>
        </Price>
        <Size>
        <HeadAttributes>
        {t('status')}
        </HeadAttributes>
        <DropAttributes>
            <select className="status" value={status} onChange={(e) => setStatus(e.target.value)}>
                <option value={'1'}>{t('forSale')}</option>
                <option value={'2'}>{t('sold')}</option>
                <option value={'3'}>{t('reserve')}</option>
            </select>
        </DropAttributes>
        </Size>
        </AttributesDropDownSecond>
        </FormHead>
        <FormHead>
        <HeadName errorTitle={errors.description}>
        {t('Description')}
        </HeadName>
        <Desc>
            <CustomRulesTextField marginLeft isTextarea value={formFields.description} name="description" onChange={inputChangeHandler} onError={inputErrorHandler} rules={Rules}/>
        </Desc>
        </FormHead>
        </FlexBox>
        <InputBox>
        <FormHead>
        <HeadDest errorTitle={errors.destination.some((item) => Boolean(item))}>
            {t('ShippingByCountry')}
            {/* <HintPrice><img alt='tooltip' src='https://svgshare.com/i/jcz.svg' /> {t('tooltip1')} <br/> {t('tooltip2')}</HintPrice> */}
        </HeadDest>
        <InputBlock>
        <PriceDest>
            <PriceHead errorTitle={errors.destination.some((item) => Boolean(item))}>
                {t('destination')}
            </PriceHead>
            <PriceDesc>
            {t('destTooltip')}
            </PriceDesc>
            {formFields.destination.map((c, index) => (
                <CustomRulesTextField 
                    key={index} 
                    value={c}
                    name={index.toString()}
                    onChange={destinationInputChangeHandler}
                    onError={destinationInputErrorHandler}
                    rules={[{ regex: /^[a-zA-Z0-9 ]+$/}]}
                />
            ))}
        </PriceDest>
        <PriceDest>
            <PriceHead>
            {t('destPrice')}
            </PriceHead>
            <PriceDesc>
            {t('destPriceTooltip')}

            </PriceDesc>
            {formFields.destPrice.map((c, index) => (
                <TextAttributes>
                    <input 
                        value={c} 
                        className='inputSecond' 
                        type="number" 
                        size="2" 
                        key={index}
                        onChange={({ currentTarget }) => {
                            setFormFields((prev) => (
                                {
                                    ...prev,
                                    destPrice: prev.destPrice.map((v, i) => i === index ? currentTarget.value : v)
                                }
                            ))
                        }}  
                    />
                    <select 
                        value={formFields.destCurrency[index]} 
                        className='selectSecond' 
                        onChange={({ currentTarget }) => 
                            setFormFields((prev) => (
                                {
                                    ...prev,
                                    destCurrency: prev.destCurrency.map((v, i) => i === index ? currentTarget.value : v)
                                }
                            ))}
                    >
                        <option value={'USD'}>USD</option>
                        <option value={'RUB'}>RUB</option>
                        <option value={'EUR'}>EUR</option>
                    </select>
                    <Trash>
                        <TrashWrapper>
                            <img src="https://svgshare.com/i/iEC.svg" 
                                onClick={() => handleClickDelete(index)} alt="trash" 
                            />
                        </TrashWrapper>
                    </Trash>
                </TextAttributes>
            ))}
        </PriceDest>
        </InputBlock>
        <Btn onClick={handleClick}>
            {t('addCountry')}
        </Btn>
        <InfoWrapper>
            <InfoPayment>
                <InfoLabel>
                    <InfoTitle errorTitle={errors.paymentDescription}>{t('paymentInfo')}</InfoTitle>
                    <CustomRulesTextField isTextarea value={formFields.paymentDescription} name="paymentDescription" onChange={inputChangeHandler} onError={inputErrorHandler} rules={Rules}/>
                </InfoLabel>
            </InfoPayment>
            <InfoDelivery>
                <InfoLabel>
                    <InfoTitle errorTitle={errors.deliveryDescription}>{t('deliveryInfo')}</InfoTitle>
                    <CustomRulesTextField isTextarea value={formFields.deliveryDescription} name="deliveryDescription" onChange={inputChangeHandler} onError={inputErrorHandler} rules={Rules}/>
                </InfoLabel>
            </InfoDelivery>
        </InfoWrapper>
        {isAuth.isMaster &&
            <CheckboxContainer>
                <input value={showpageDisplayed} onChange={(e) => {setShowpageDisplayed(e.target.checked)}} id='online-show' type='checkbox'/>
                <label for='online-show'>{t('showParticipantWork')}</label>
            </CheckboxContainer>
        }
        </FormHead>
        <Check>
            {/* <input type="checkbox" id='check' name='check'/>
            <label htmlFor='check'>Отослать моим подписчикам уведомление об этой работе</label> */}
        </Check>
        </InputBox>
        <Buttn onClick={onSubmit}>
            {t('place')}
        </Buttn>
    </Section>
  )
}

export default Add
