import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { fetchUpdateMe, selectIsAuth } from '../../redux/slices/auth'
import axios from '../../utils/axios'
import { useTranslation } from 'react-i18next'


const Description = styled.section`
margin-left: 25px;
margin-bottom: 30px;
position: relative;

@media (max-width: 577px){
  margin: 0;
    padding: 80px 20px;
}
`
const Head = styled.h2`
font-family: 'Palatino';
font-weight: 400;
border-bottom: 0.5px solid rgba(215, 215, 215, 1);
line-height: 35px;
display: flex;
img{
  width: 20px;
  opacity: 0.7;
  margin: 0 1rem;
}

span{
  font-size: 18px;
  opacity: 0.9;
  font-family: 'Montserrat';
  margin-left: 10px;
  margin-top: 2px;
}

input{
  margin-top: -1rem;
  margin-bottom: 1rem;
  width: 470px;
  height: 35px;
  border: 1px solid #949494;
  border-radius: 10px;
  outline: none;
  text-indent: 5px;
}
`
const Body = styled.p`
margin-top: 1.7rem;
font-family: 'Montserrat';
font-size: 17px;
font-weight: 400;
display: flex;
line-height: 25px;

img{
  width: 20px;
  opacity: 0.7;
  margin: -0.3rem 0.5rem;
}

textarea{
  font-family: 'Montserrat';
  width: 526px;
  height: 160px;
  border: 1px solid #949494;
  border-radius: 10px;
  outline: none;
  resize: none;
  padding: 5px 5px;
}

`

const Save = styled.button`
width: 150px;
height: 35px;
background: rgba(254, 97, 3, 0.5);
border: 1px solid rgba(0, 0, 0, 0);
border-radius: 15px;
font-family: 'Montserrat';
font-size: 17px;
margin-top: -1rem;
margin-left: 2rem;
opacity: 0.5;
z-index: 12;
transition: 0.3s ease;

&:hover{
  opacity: 1;
}
`

const SaveDesc = styled.button`
width: 150px;
height: 35px;
background: rgba(254, 97, 3, 0.5);
border: 1px solid rgba(0, 0, 0, 0);
border-radius: 15px;
font-family: 'Montserrat';
font-size: 17px;
margin-top: 0rem;
margin-left: 2rem;
opacity: 0.5;
z-index: 12;
transition: 0.3s ease;

&:hover{
  opacity: 1;
}
`


const Text = () => {
  const isAuth = useSelector(selectIsAuth)
  const dispatch = useDispatch()
  const [isShown, setIsShown] = React.useState(false)
  const [Desc, setDesc] = React.useState(false)
  const { t } = useTranslation();

  const {
    register:
    registerDesc, 
    handleSubmit:
    handleSubmitDesc, 
    formState : {errors: errorsDesc, isValid: isValidDesc}} = useForm({
    defaultValues:{
      description: isAuth.description,
    },
    mode: 'onChange'
  })

  const {register, handleSubmit, formState : {errors, isValid}} = useForm({
    defaultValues:{
      status: isAuth.status,
    },
    mode: 'onChange'
  })

  const toggle = () => {
    setIsShown(!isShown);
  }

  const toggleDesc = () => {
    setDesc(!Desc);
  }

  const onSubmit = async (values) =>{
    const data = await dispatch(fetchUpdateMe(values))

    if (!data.payload) {
      return alert('Не удалось авторизоваться')
    }

    setIsShown(false);
    setDesc(false);
  }

  return (
    <>
    
    <Description>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Head>
            {!isShown ?
            <div>{isAuth.status === '' ? <>{t('justStatus')} <span>({t('inEhglish')})</span> </> : <>{isAuth.status}</>}<img src='https://svgshare.com/i/k77.svg' alt='Pencil' onClick={toggle}/> </div>
            :
            <>      
              <input type="text" {...register('status')}/>
              <Save type="submit">{t('saveBanner')}</Save>
            </>
            }
          </Head>
        </form>
        <form onSubmit={handleSubmitDesc(onSubmit)}>
          {!Desc ?
            <Body>{isAuth.description === '' ? t('descriptionYourStore'): <>{isAuth.description}</>}<img src='https://svgshare.com/i/k77.svg' alt='Pencil' onClick={toggleDesc}/></Body>
            : 
            <Body>
              <textarea {...registerDesc('description')}></textarea>
              <SaveDesc type="submit">{t('saveBanner')}</SaveDesc>
            </Body>
          }
        </form>
    </Description>
    
    </>
  )
}

export default Text