import React from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'


const Btn = styled.button`
position: absolute;
width: 220px;
height: 36px;
background: rgba(189, 169, 158, 0.1);
display: inline-block;
color:  #000;
outline: none;
border: 1px solid rgba(189, 200, 180, 0.1);
font-family: "Montserrat", sans-serif;
font-size: 16px;
font-weight: 500;
padding: 0.2rem 0.5rem;
border-radius: 10px;
right: 20px;
cursor: pointer;
opacity: 1;
  div{
    opacity: 0.9;
  }

@media (max-width: 577px){
  margin-left: 0;
  width: 120px;
  margin-left: 0;
  font-size: 12px;

  img{
    margin: 1px 0px;
  }
}
`

const ImgArrow = styled.img`
    width: 16px;
    height: 15px;
    margin-left: 0 !important;
    display: block;

    @media (max-width: 577px){
      margin: 0 !important;
    }
`

const BtnInner = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`


const ButtonMarket = () => {

  const { t } = useTranslation()

  return (
    <Btn>
       <BtnInner><div>{t('gotoshop')} </div><ImgArrow src="https://svgshare.com/i/iDf.svg" alt="arrow"/></BtnInner>
    </Btn>
  )
}

export default ButtonMarket