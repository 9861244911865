import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import axios from '../../../utils/axios'
import { Link } from 'react-router-dom'
import CardPreview from '../../Card/card-preview'

import defaultToy from './../../../assets/defaultToy.png'
import defaultAvatar from './../../../assets/cardPhoto.png'
import { selectIsAuth } from '../../../redux/slices/auth'
import { useSelector } from 'react-redux'

const Card = styled.section`
    width: 900px;
    margin: 2rem 0rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    justify-content: center;

    @media (max-width: 577px){
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 50px 10px;
        justify-content: flex-start;
        padding: 0 5px;
        box-sizing: border-box;
    }
`

const CardBlock = () => {
    const [cards, setCards] = useState([])
    const isAuth = useSelector(selectIsAuth)

    const fetchMyCards = async () => {
        try {
            const { data } = await axios.get('/market/user/me/liked')
            setCards(data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchMyCards()
    }, [])

  if (!cards){
      return(
      <div>Загрузка</div>)
    }  
  return (
    <Card>
        {cards?.reverse().filter((card) => !!card).map((card, index) => (
            <CardPreview
                link={`/card/${card._id}`}
                key={card._id}
                altWork={card.name} 
                imageWork={card.imgUrl ?`https://toobears.com/api/${card.imgUrl[0]}` : defaultToy}
                avatar={`https://toobears.com/api/${card.author.avatarUrl}` || defaultAvatar}
                shopName={card.author.shopname || `${card.author.username} ${card.author.surname}`}
                nameWork={card.name}
                price={card.price + ' ' + card.currency}
                size={card.size + 'cm'}
                likeActive={card.like.includes(isAuth && isAuth._id)}    
                status={card.status}  
            />
        ))}
    </Card>
  )
}

export default CardBlock