import { useTranslation } from 'react-i18next'
import {Root, Title, Border, Text, Date, TitlePage
} from './styles';
import New from './new/new';

const News = () => {
    const { t } = useTranslation();

    return (
        <Root>
            <TitlePage>{t('News')}</TitlePage>
            <New title={t('ScaryPartyStart')} text={t('ScaryPartyStartText')} date='01.07.2024'/>
            <New title={t('FairyTailEnd')} text={t('FairyTailEndText')} date='18.06.2024'/>
            <New title={t('FairyTailStart')} text={t('FairyTailStartText')} date='05.03.2024'/>
            <New title={t('SpringEnd')} text={t('SpringEndText')} date='24.02.2024'/>
            <New title={t('OnlineShowTooBears')} text={t('News1')} date='24.11.2023'/>
        </Root>
    )
}

export default News;