import { useTranslation } from 'react-i18next'
import {ParticipantListSmall, ParticipantItem, 
    ParticipantListWrapperMobile, ParticipantListWrapper, ShowListBenefitItem
} from './../../styles';

const Participants = () => {
    const { t } = useTranslation();

    return (
        <>
            <ParticipantListWrapper>
                <ParticipantListSmall>
                    <ShowListBenefitItem>{t('ElenaKlevakina')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('NatalyaVasileva')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('IrinaDrozdova')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('DanataTselinskaya')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('NatashaBelyaeva')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('ElenaKuzmina')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('ElenaSikerina')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('AnastasiaUlaysi')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('AnnaMoiseenko')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('MarinaGolovacheva')}</ShowListBenefitItem>
                </ParticipantListSmall>
                <ParticipantListSmall start="11">
                    <ShowListBenefitItem>{t('KirstiLiljaaho')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('TanyaShchepina')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('ValentinaKrizhanovskaya')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('EvgeniyaSavostyanova')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('OlgaKolpakova')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('TatianaSadakova')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('IrinaChalaya')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('SvetlanaKrantova')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('OxanaSorokina')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('AnnaKoshcheeva')}</ShowListBenefitItem>
                </ParticipantListSmall>
                <ParticipantListSmall start="21">
                    <ShowListBenefitItem>{t('NatalieKuznetsova')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('SvetlanaAgeeva')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('RushanaTim')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('NadezhdaFrolova')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('SvetlanaBogomolova')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('OlgaEtz')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('GulnaraIlyasova')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('MariaMakhovskaya')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('IngridvanIperen')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('AlexanderVoroshev')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('AllaBulgakova')}</ShowListBenefitItem>
                </ParticipantListSmall>
            </ParticipantListWrapper>
            <ParticipantListWrapperMobile>
                <ParticipantListSmall>
                    <ParticipantItem>{t('ElenaKlevakina')}</ParticipantItem>
                    <ParticipantItem>{t('NatalyaVasileva')}</ParticipantItem>
                    <ParticipantItem>{t('IrinaDrozdova')}</ParticipantItem>
                    <ParticipantItem>{t('DanataTselinskaya')}</ParticipantItem>
                    <ParticipantItem>{t('NatashaBelyaeva')}</ParticipantItem>
                    <ParticipantItem>{t('ElenaKuzmina')}</ParticipantItem>
                    <ParticipantItem>{t('ElenaSikerina')}</ParticipantItem>
                    <ParticipantItem>{t('AnastasiaUlaysi')}</ParticipantItem>
                    <ParticipantItem>{t('AnnaMoiseenko')}</ParticipantItem>
                    <ParticipantItem>{t('MarinaGolovacheva')}</ParticipantItem>
                    <ParticipantItem>{t('KirstiLiljaaho')}</ParticipantItem>
                    <ParticipantItem>{t('TanyaShchepina')}</ParticipantItem>
                    <ParticipantItem>{t('Valentina')} {t('Krizhanovskaya')}</ParticipantItem>
                    <ParticipantItem>{t('Evgeniya')} {t('Savostyanova')}</ParticipantItem>
                    <ParticipantItem>{t('OlgaKolpakova')}</ParticipantItem>
                </ParticipantListSmall>
                <ParticipantListSmall start="16">
                    <ParticipantItem>{t('TatianaSadakova')}</ParticipantItem>
                    <ParticipantItem>{t('IrinaChalaya')}</ParticipantItem>
                    <ParticipantItem>{t('SvetlanaKrantova')}</ParticipantItem>
                    <ParticipantItem>{t('OxanaSorokina')}</ParticipantItem>
                    <ParticipantItem>{t('AnnaKoshcheeva')}</ParticipantItem>
                    <ParticipantItem>{t('NatalieKuznetsova')}</ParticipantItem>
                    <ParticipantItem>{t('SvetlanaAgeeva')}</ParticipantItem>
                    <ParticipantItem>{t('RushanaTim')}</ParticipantItem>
                    <ParticipantItem>{t('NadezhdaFrolova')}</ParticipantItem>
                    <ParticipantItem>{t('SvetlanaBogomolova')}</ParticipantItem>
                    <ParticipantItem>{t('OlgaEtz')}</ParticipantItem>
                    <ParticipantItem>{t('GulnaraIlyasova')}</ParticipantItem>
                    <ParticipantItem>{t('MariaMakhovskaya')}</ParticipantItem>
                    <ParticipantItem>{t('IngridvanIperen')}</ParticipantItem>
                    <ParticipantItem>{t('AlexanderVoroshev')}</ParticipantItem>
                    <ParticipantItem>{t('AllaBulgakova')}</ParticipantItem>
                </ParticipantListSmall>
            </ParticipantListWrapperMobile>
        </>
    )
}

export default Participants;