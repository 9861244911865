import React, {useEffect, useState} from 'react'
import styled from 'styled-components/macro'
import Logo from './Logo'
import Searchbar from './Searchbar'
import Button from './Button'
import ButtonFlag from './ButtonFlag'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsAuth } from '../../../redux/slices/auth'
import { logout } from '../../../redux/slices/auth'
import { useTranslation } from 'react-i18next'
import search from './../../../assets/search.svg';
import close from './../../MessageModal/close.svg';

const Section = styled.nav`

@media (max-width: 577px){
    margin-bottom: 0;
}
@media (max-width: 380px){
    width: 100%;
}
`
const TopNav = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width: 75%;
margin: 1rem auto;
@media (max-width: 577px){
    width: calc(100% - 3rem);
    margin: 1rem 3rem;
}
`

const SearchbarCont = styled.div`
margin-top: 0rem;
margin-right: -5rem;

@media (max-width: 577px) {
    display: none;
}
`

const SearchbarMobileCont = styled.div`
display: none;

@media (max-width: 577px) {
    display: ${props => props.openMobileSearch ? 'flex' : 'none'};
    align-items: center;
    padding-left: 10px;
    margin-left: 10px;
}
`

const ButtonContainer = styled.div`
display: flex;
width: 300px;
margin-right: 7px;
justify-content: right;
position: relative;

.username{
    position: relative;
    bottom: -5px;
    right: 1rem;
}
.arrow{
    width: 20px;
    opacity: 0.7;
    margin-bottom: -5px;
    margin-right: 0px;
    cursor: pointer;
}

.drop-down{
    position: absolute;
    top: 38px;
    left: 154px;
    background: rgba(234, 226, 218, 1);
    border-radius: 20px;
    padding: 10px 20px;
    width: 200px;
}

.drop-down::before {
    content: '';
    position: absolute;
    top: -11px;
    left: 25px;
    height: 20px;
    width: 20px;
    background: rgba(234, 226, 218, 1);
    transform: rotate(45deg);
}

.drop-down.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: 0.5s ease;
    z-index: 25565;
    color: rgba(0, 0, 0, 0.8);
}

.drop-down.inactive {
    opacity: 0;
    visibility: hidden;
    transform: translateY(0px);
    transition: 0.5s ease;
}


h3{
    width: 100%;
    text-align: center;
    font-size: 10px;
    padding: 0px 0;
    font-weight: 500;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.8);
    line-height: 1.6rem;
}

h3 span{
    font-size: 14px;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 400;
}

.drop-down ul li{
    padding: 10px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.drop-down ul li:hover a{
}
.drop-down ul li:hover img{
    opacity: 1;
}
.dropdownItem {
    display: flex;
    margin: 10px auto;
}
.dropdownItem img{
    max-width: 20px;
    margin-right: 5px;
    margin-left: 40px;
    opacity: 0.5;
    transition: 0.5s ease;
}

.dropdownItem a{
    max-width: 100px;
    margin-left: 10px;
    transition: 0.5s ease;
}

h5{
    font-size: 12pt;
    font-weight: 500;
}

@media (max-width: 577px){
    width: fit-content;
    margin-right: 0;

    .drop-down{
        display: none;
    }
}

`
const BotNav = styled.div`
background-color: rgba(234, 226, 218, 0.6);
height: 60px;
margin-top: -0.7rem;

@media (max-width: 577px){
    display: flex;
    justify-content: space-between;
}
`

const Menu = styled.ul`
    display: ${props => props.openMobileSearch ? 'none' : 'flex'};
    list-style: none;
    justify-content: center;
    align-items: center;
    height: 60px;
    text-transform: uppercase;
    font-family: 'Palatino';
    font-size: 13px;
    font-weight: 500;

    .item-hide {
        display: none;
    }

@media (max-width: 577px){
    justify-content: left;
    margin-left: 0.5rem;
    .item{
        display: none;
    }

    .item-hide {
        display: block;
    }
}
`

const MenuItem = styled.li`
margin: 0 1.6rem;
color: #000;
cursor: pointer;
transition: all 0.4s ease;
&:hover{
    transform: scale(1.3);
}

@media (max-width: 577px){
margin: 0.5rem;
width: 150;
}
`

const MenuAdapt = styled.div`
width: 153px;
height: auto;
background-color: #FEF5EC;
position: absolute;
z-index: 10000;
right: 0;
display: none;
border: 1px solid rgba(148, 148, 148, 0.18);
border-radius: 20px 0 0 20px;
top: 44px;
@media (max-width: 577px){
    display: ${props => props.view};
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
}
`

const List = styled.ul`
list-style: none;
font-family: 'Montserrat';
margin-top: 0rem;
`

const ListItem = styled.li`
font-size: 14pt;
margin-left: 0rem;
padding-left: 1rem;
border-bottom: 1px solid rgba(148, 148, 148, 0.18);
padding-bottom: 0.4rem;
padding-top: 0.4rem;
cursor: pointer;

&:last-child {
    border: none;
}
`

const SearchMobileBtn = styled.div`
    display: none;

    @media (max-width: 577px){
        cursor: pointer;
        padding-right: 10px;
        display: flex;
        align-items: center;

        img {
            display: block;
            width: 22px;
        }

        .close {
            width: 14px;
        }
    }
`

const Navbar = () => {

 const isAuth = useSelector(selectIsAuth)
 const dispatch = useDispatch()
 const [open, setOpen] = useState(false);
 const [openMobileSearch, setOpenMobileSearch] = useState(false);

 const { t } = useTranslation()


 const onClickLogout = () => {
    dispatch(logout())
    window.location.replace('/')
    window.localStorage.removeItem('token')  
 }


  return (
    <Section>
        <TopNav>
            <Link to="/"><Logo /></Link>
            <SearchbarCont>
                <Searchbar placeholder={t('search')}/>
            </SearchbarCont>
            <ButtonContainer>
                {isAuth ? 
                    <>
                        <div style={{fontSize: 16, height: '20px', textAlign: 'right'}} className="username">
                        <Link to="/account"> {`${isAuth.username}`}</Link>
                            <img src='https://svgshare.com/i/jeQ.svg' className='arrow' alt='#'
                                onClick={() =>{
                                    setOpen(!open);
                                    window.innerWidth <= 577 &&
                                    setTimeout(() => {
                                        setOpen(false);
                                      }, 11000);
                                }}
                            />
                        </div>
    
                        <div className={`drop-down ${open ? 'active' : 'inactive'} `}>
                            <h3>{`${isAuth.username}`} {`${isAuth.surname}`} <br /> {isAuth.shopname != null ? `${isAuth.shopname}` : ''}</h3>
                            <ul>
                            <Link to="/account"><li className = 'dropdownItem'>
                                    <img src='https://svgshare.com/i/jdo.svg' alt='profile' />
                                    <h5>{t('profile')}</h5>
                                </li></Link>

                                <li className = 'dropdownItem' onClick={onClickLogout}>
                                    <img src='https://svgshare.com/i/jda.svg' alt='logout' />
                                    <h5>{t('logout')}</h5>
                                </li>
                            </ul>
                        </div>

                        <MenuAdapt isAuth={isAuth.role} view={open ? 'flex' : 'none'}>
                            <List>
                                <ListItem><Link to="/account">{t('profile')}</Link></ListItem>
                                <ListItem>
                                {isAuth.role === 'Seller' ? 
                                    <Link to="/account/Orders">{t('orders')}</Link> 
                                        :
                                    <Link to="/account">{t('orders')}</Link>}
                                </ListItem>
                                {isAuth.role === 'Seller' && <ListItem><Link to="/account/AddWork">{t('addWork')}</Link></ListItem>}  
                                {isAuth.role === 'Seller' && <ListItem><Link to="/account/CheckWork">{t('myWork')}</Link></ListItem>}
                                <ListItem><Link to="/account/Message">{t('messenger')}</Link></ListItem>  
                                <ListItem><Link to="/account/Favorite">{t('favorite')}</Link></ListItem>  
                                <ListItem>{isAuth.role === 'Seller' ? 
                                    <Link to="/account/SettingsMenu">{t('settings')}</Link> 
                                        :
                                    <Link to="/account/Update">{t('settings')}</Link>}
                                </ListItem>  
                                <ListItem onClick={onClickLogout}>{t('logout')}</ListItem>  
                            </List>
                        </MenuAdapt>
                    </>
                    : (<Link to="/auth/login"><Button text={t('login')} /></Link>)}
                <ButtonFlag text="RU" />
            </ButtonContainer>
        </TopNav>
        <BotNav>
            <SearchbarMobileCont openMobileSearch={openMobileSearch}>
                <Searchbar  placeholder={t('search')}/>
            </SearchbarMobileCont>
            <Menu openMobileSearch={openMobileSearch}>
                <MenuItem className='item'><Link to='cathegory'>{t('cathegory')}</Link></MenuItem>
                <MenuItem className='item'><Link to='auctions'>{t('auctions')}</Link></MenuItem>
                <MenuItem className='item-hide'><Link to='/'>{t('Main')}</Link></MenuItem>
                <MenuItem><Link to='show' >{t('show')}</Link></MenuItem>
                <MenuItem className='item'><Link to='masterclass' >{t('pics')}</Link></MenuItem>
                <MenuItem className='item'><Link to='patterns' >{t('patterns')}</Link></MenuItem>
                {/* <MenuItem className='item'><Link to='masters' className='link'>{t('masters')}</Link></MenuItem> */}
                <MenuItem><Link to='news'>{t('news')}</Link></MenuItem>
            </Menu>
            <SearchMobileBtn onClick={() =>{setOpenMobileSearch(!openMobileSearch)}}>
                {openMobileSearch ? 
                    <img className='close' src={close} alt='Close'/> :
                    <img src={search} alt='SearchMobile'/>
                }            
            </SearchMobileBtn>
        </BotNav>
    </Section>
  )
}

function DropdownItem(props){
    return(
        <li className = 'dropdownItem'>
            <img src={props.img} alt='logout' />
            <h5>{props.text}</h5>
        </li>
    )
}

export default Navbar