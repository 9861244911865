import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { Link, useNavigate, useParams } from 'react-router-dom'
import defaultToy from './../../assets/defaultToy.png'
import avatar from './../../components/UserLK/avatar.png'
import mark from './../../assets/mark.svg'
import countriesLanguages from '@hotosm/iso-countries-languages'

import axios from '../../utils/axios'
import { useTranslation } from 'react-i18next'
import MessageModal from '../MessageModal/MessageModal'
import { OLD_KEYS_COUNTRY_MAP } from '../../const/old-keys-country-map/old-keys-country-map'

const Root = styled.div`
    font-family: 'Montserrat';
    flex: 1 1 100%;
    box-sizing: border-box;

    @media (max-width: 577px){
        padding: 0 15px;
    }
`

const Title = styled.div`
    box-sizing: border-box;
    font-size: 28px;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(148, 148, 148, 0.5) ;
    display: flex;
    justify-content: space-between;
    font-family: 'Palatino Linotype';

    @media (max-width: 577px){
        padding-bottom: 18px;
    }
`

const TitleBtn = styled.div`
    background: #F5F5F5;
    cursor: pointer;
    border-radius: 10px;
    height: 45px;
    width: 110px;
    font-size: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 577px){
        width: 95px;
        height: 35px;
        font-size: 14px;
    }

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
`

const TitleText = styled.div`
    display: flex;
    align-items: center;
    font-weight: 500;

    @media (max-width: 577px){
        font-weight: 400;
        font-size: 20px;
    }
`

const InfoOrder = styled.div`
    display: flex;
    margin-bottom: 60px;

    @media (max-width: 577px){
        margin-bottom: 40px;
        flex-direction: column ;
    }
`

const OrderWrapper = styled.div`
    padding-top: 20px;
    display: flex;
    align-items: center;
    margin-right: 60px;
    flex: 1 1 100%;

    @media (max-width: 577px){
        flex-direction: column;
        margin: 0 auto;
    }
`

const ShopWrapper = styled.div`
    padding-top: 20px;
    flex: 1 1 100%;
`

const Shop = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 9px 50px 30px;
    border: 1px solid rgba(171, 154, 144, 0.5);
    border-radius: 30px;
    flex: 1 1 100%;
    max-width: 280px;
    box-sizing: border-box;

    @media (max-width: 577px){
        margin: 0 auto;
    }
`

const ShopImg = styled.img`
    border: 1px solid rgba(171, 154, 144, 0.5);
    border-radius: 50%;
    width: 80px;
    margin-bottom: 12px;
    object-fit: cover;
    height: 80px;
`

const ShopName = styled.div`
    margin-bottom: 9px;
    font-weight: 700;
    font-size: 19px;
    opacity: 0.8;
    line-height: 23px;

    @media (max-width: 577px){
        font-size: 15px;
    }
`

const ShopCountry = styled.div`
    display: flex;
    margin-bottom: 10px;
    align-items: center;
`

const ShopCountryImg = styled.img`
    display: block;
    width: 16px;
`

const ShopCountryText = styled.div`
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;

    @media (max-width: 577px){
        font-size: 13px;
    }
`

const ShopBtn = styled.div`
    cursor: pointer;
    width: 230px;
    height: 50px;
    background: rgba(254, 97, 3, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-bottom: 12px;
`

const OrderShow = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 40px;

    @media (max-width: 577px){
        margin-right: 0;
    }
`

const OrderImg = styled.img`
    display: block;
    width: 198px;
    margin-bottom: 10px;
    object-fit: cover;
    height: 245px;
    border-radius: 10px;
`

const OrderTitle = styled.div`
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    font-family: 'Palatino Linotype';

    @media (max-width: 577px){
        font-weight: 400;
        font-size: 16px;
    }
`

const OrderDesc = styled.div`
    @media (max-width: 577px){
        display: flex ;
    }
`
const OrderDescWrapper = styled.div`
    @media (max-width: 577px){
        margin-right: 20px;
        margin-top: 10px;

        :last-child {
            margin-right: 0;
        }
    }

`

const OrderDescItem = styled.div`
    font-size: 16px;
    margin-bottom: 8px;

    @media (max-width: 577px){
        font-size: 15px;
    }
`

const OrderDescItemTitle = styled.div`
    font-weight: 500;
    margin-bottom: 8px;
`

const OrderDescItemDesc = styled.div`
`

const ShippingInfo = styled.div`
    display: flex;
    margin-bottom: 56px;

    @media (max-width: 577px){
        flex-direction: column;
        margin-bottom: 15px;
    }
`

const ShippingInfoSecond = styled.div`
    display: flex;
    margin-bottom: 56px;

    @media (max-width: 577px){
        flex-direction: column-reverse;
    }
`

const ShippingItem = styled.div`
    flex: 1 1 100%;
    margin-right: 50px;

        :last-child {
            margin-right: 0;
        }

    @media (max-width: 577px){
        margin-right: 0;
    }
`

const ShippingItemTitle = styled.div`
    font-weight: 400;
    font-size: 24px;
    line-height: 42px;
    font-family: 'Palatino Linotype';
    border-bottom: 1px solid rgba(148, 148, 148, 0.7);

    @media (max-width: 577px){
        font-size: 20px;
    }
`

const ShippingItemDesc = styled.div`
    padding-top: 20px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;

    @media (max-width: 577px){
        font-size: 16px;
    }
`

const MessageField = styled.div`
    border: 1px solid rgba(148, 148, 148, 0.5);
    border-radius: 10px;
    padding: 20px 40px 25px 20px;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Montserrat';
    margin-top: 20px;

    @media (max-width: 577px){
        font-size: 15px;
    }
`

const OrderInfo = () => {
    const { id } = useParams();
    const { t, i18n } = useTranslation();
    const [order, setOrder] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const navigate = useNavigate();

    const country = countriesLanguages.getCountry(i18n.language, OLD_KEYS_COUNTRY_MAP[order?.seller.country] || order?.seller.country);
    
    const translatedCountry = order?.seller.country ? (country || t('russia')) : t('russia');

    const getOrders = useCallback(async () => {
        const { data } = await axios.get(`/orders/${id}`);
        setOrder(data[0]);
      }, [id])

    useEffect(() => {
        getOrders()
    }, [getOrders])
    

    const formatDate = (newDate) => {
        const months = {
            0: '01',
            1: '02',
            2: '03',
            3: '04',
            4: '05',
            5: '06',
            6: '07',
            7: '08',
            8: '09',
            9: '10',
            10: '11',
            11: '12',
          }
        const d = newDate
        const year = d.getFullYear()
        const date = d.getDate()
        const monthName = months[d.getMonth()]
        const formatted = `${date}.${monthName}.${year}`
        return formatted.toString()
      }

    const sendMessage = async (text, attachment) => {
        await axios.post('/chats', {
          user: order.seller._id,
          text,
          attachment
        });
        navigate('/account/Message');
    }

    return order && order.card ? (
        <Root>
            <Title>
                <TitleText>
                    {t('orderInfo')}: {order.card.name}
                </TitleText>
                <TitleBtn>
                    <Link to='/account'>
                        {t('back')}
                    </Link>
                </TitleBtn>
            </Title>
            <InfoOrder>
                <OrderWrapper>
                    <OrderShow>
                        <OrderImg src={order.card.imgUrl ? `https://toobears.com/api/${order.card.imgUrl[0]}` : defaultToy} alt='image of order'/>
                        <OrderTitle>{order.card.name}</OrderTitle>
                    </OrderShow>
                    <OrderDesc>
                        <OrderDescWrapper>
                            <OrderDescItem>
                                <OrderDescItemTitle>
                                    {t('orderDate')}:
                                </OrderDescItemTitle>
                                <OrderDescItemDesc>
                                    {formatDate(new Date(order.createdAt))}
                                </OrderDescItemDesc>
                            </OrderDescItem>
                            <OrderDescItem>
                                <OrderDescItemTitle>
                                    {t('order')}:
                                </OrderDescItemTitle>
                                <OrderDescItemDesc>
                                    {order.orderId}
                                </OrderDescItemDesc>
                            </OrderDescItem>
                            <OrderDescItem>
                                <OrderDescItemTitle>
                                    {t('price')}:
                                </OrderDescItemTitle>
                                <OrderDescItemDesc>
                                    {order.card.price} {order.card.currency}
                                </OrderDescItemDesc>
                            </OrderDescItem>
                        </OrderDescWrapper>
                        <OrderDescWrapper>
                            <OrderDescItem>
                                <OrderDescItemTitle>
                                    {t('shippingCost')}:
                                </OrderDescItemTitle>
                                <OrderDescItemDesc>
                                    {order.card.delivery[0].destPrice[0]} {order.card.delivery[0].destCurrency[0]}
                                </OrderDescItemDesc>
                            </OrderDescItem>
                            <OrderDescItem>
                                <OrderDescItemTitle>
                                    {t('payMethod')}:
                                </OrderDescItemTitle>
                                <OrderDescItemDesc>
                                    Money Order
                                </OrderDescItemDesc>
                            </OrderDescItem>
                        </OrderDescWrapper>
                    </OrderDesc>
                </OrderWrapper>
                <ShopWrapper>
                    <Shop>
                        <ShopImg alt='shop-name' src={order.seller.avatarUrl ? `https://toobears.com/api/${order.seller.avatarUrl}` : avatar}/>
                        <ShopName>{order.seller.shopname} </ShopName>
                        <ShopCountry>
                            <ShopCountryImg src="https://svgshare.com/i/iCj.svg" alt='mark' />
                            <ShopCountryText>{translatedCountry}</ShopCountryText>
                        </ShopCountry>
                        <ShopBtn onClick={() => {setOpenModal(true)}}>
                            {t('sendMessage')}
                        </ShopBtn>
                    </Shop>
                </ShopWrapper>
            </InfoOrder>
            <ShippingInfo>
                <ShippingItem>
                    <ShippingItemTitle>
                        {t('shippingAddress')}
                    </ShippingItemTitle>
                    <ShippingItemDesc>
                        {order.shippingName} <br />
                        {order.shippingAddress}<br />
                        {order.shippingCity} <br />
                        {order.shippingState} <br />
                        {order.shippingCountry} <br />
                        {order.shippingPhone} <br />
                        {order.shippingPortalCode}
                    </ShippingItemDesc>
                </ShippingItem>
                <ShippingItem>
                    <ShippingItemTitle>
                        {t('shippingPolicy')}
                    </ShippingItemTitle>
                    <ShippingItemDesc>
                        {order.card.deliveryDescription}
                    </ShippingItemDesc>
                </ShippingItem>
            </ShippingInfo>
            <ShippingInfoSecond>
                <ShippingItem>
                    <ShippingItemTitle>
                        {t('messageToSeller')}
                    </ShippingItemTitle>
                    <MessageField>
                        {order.message}
                    </MessageField>
                </ShippingItem>
                <ShippingItem>
                    <ShippingItemTitle>
                        {t('paymentPolicy')}
                    </ShippingItemTitle>
                    <ShippingItemDesc>
                        {order.card.paymentDescription}
                    </ShippingItemDesc>
                </ShippingItem>
            </ShippingInfoSecond>
            {openModal &&
                <MessageModal
                    onSend={sendMessage}
                    defaulValue=''
                    to={`${order.seller.username} ${order.seller.surname}`}
                    theme={order.card.name}
                    onClose={() => {setOpenModal(false)}}
                />
            }
        </Root>
    )       :
        null
}

export default OrderInfo;
