import styled from 'styled-components/macro';
import banner from './banner-bear.png';
import bannerMobile from './banner-bear-mobile.png';
import stars from './img/star.svg';

export const Root = styled.div`
    box-sizing: border-box;
    padding: 65px 290px;

    ul {
        padding-left: 25px;
    }

    @media (max-width: 577px){
        padding: 40px 10px;
    }
`

export const ButtonContact = styled.button`
    text-decoration: underline;
    color: rgba(254, 97, 3, 0.50); 
    background: none;
    border: none;
    cursor: pointer;
    font-family: Montserrat;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;

    @media (max-width: 577px){
        font-size: 15px; 
    }
`

export const Title = styled.div`
    color: rgba(0, 0, 0, 0.80);
    text-align: center;
    font-family: Palatino Linotype;
    font-size: 50px;
    font-weight: 400;
    margin-bottom: 48px;

    @media (max-width: 577px){
        font-size: 30px;
        margin-bottom: 15px;
    }
`

export const Description = styled.div`
    color: #000;
    font-family: Montserrat;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 65px;

    @media (max-width: 577px){
        font-size: 15px; 
        margin-bottom: 30px;
    }
`

export const SubTitle = styled.div`
    color: rgba(0, 0, 0, 0.80);
    font-family: Palatino Linotype;
    font-size: 35px;
    font-weight: 400;
    margin-bottom: 15px;

    @media (max-width: 577px){
        font-size: 25px; 
    }
`

export const SubTitleColor = styled.span`
    color: #9D6E49; 
    font-family: Palatino Linotype;
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 15px;

    @media (max-width: 577px){
        font-size: 25px; 
    }
`

export const BannerBear = styled.div`
    background: url(${banner}) no-repeat;
    background-position: top;
    width: 100%;
    height: 443px;

    
    @media (max-width: 577px){
        background: url(${bannerMobile}) no-repeat;
        font-size: 25px; 
        height: 287px;
    }
`

export const AuthorBlockWrapper = styled.div`
`

export const AuthorBlock = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 35px;
    margin-bottom: 42px;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        display: block;
        background: url(${stars});
        width: 41px;
        height: 35px;
        right: -130px;
        top: -28px;
	}

    @media (max-width: 577px){
        display: block;
        margin-bottom: 30px;
        margin-top: 30px;

        &:before {
            display: none;
	    }
    }
`

export const AvatarBlock = styled.div`
    box-sizing: border-box;
    flex: 0 0 auto;
    border: 1px solid rgba(171, 154, 144, 0.50);
    padding: 50px 30px 30px;
    border-radius: 30px;
    margin-right: 35px;
    width: 430px;
    height: 350px;
    position: relative;

    img {
        width: 200px;
        display: block;
        margin-left: auto;
    }

    span {
        display: block;
        text-align: center;
    }

    &:after {
        content: '';
        position: absolute;
        display: block;
        background: url(${stars});
        width: 41px;
        height: 35px;
        left: 26px;
        top: -18px;
	}

    &:before {
        content: '';
        position: absolute;
        display: block;
        background: url(${stars});
        width: 41px;
        height: 35px;
        left: 65px;
        bottom: -5px;
	}

    @media (max-width: 577px){
        padding: 40px 25px 25px;
        width: 359px;
        height: 293px;
        margin: 0 auto 44px;
        img {
            width: 165px;
        }

        &:before {
            left: 235px;
            bottom: -18px;
	    }
    }

    @media (max-width: 400px){
        padding: 35px 20px 20px;
        width: 300px;
        height: 250px;
        img {
            width: 145px;
        }
    }
`

export const TextBlock = styled.div`
    color: #000;
    font-family: Montserrat;
    font-size: 25px;
    line-height: 30px; 

    @media (max-width: 577px){
        font-size: 15px;
        line-height: 20px; 
        margin-bottom: 44px;
    }
`

export const TeamBlock = styled.div`
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    gap: 35px;
    margin-bottom: 50px;

    @media (max-width: 577px){
        display: block;
    }
`

export const TeamItem = styled.div`
    box-sizing: border-box; 
    color: #000;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    flex: 1 1 100%;

    @media (max-width: 577px){
        font-size: 15px; 
        margin-bottom: 50px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`

export const TeamTitle = styled.div`
    box-sizing: border-box;
    color: #9D6E49;
    font-family: Palatino Linotype;
    font-size: 24px;
    font-weight: 700;
    line-height: 42px;
    padding: 40px 0;
    border-radius: 30px;
    border: 1px solid rgba(171, 154, 144, 0.50);
    margin-bottom: 32px;
    text-align: center;

    @media (max-width: 577px){
        margin-bottom: 15px;
        padding: 25px 0;
    }
`

export const TeamTitleWithStarIllustrator = styled(TeamTitle)`
    position: relative;
    &:before {
        content: '';
        position: absolute;
        display: block;
        background: url(${stars});
        width: 41px;
        height: 35px;
        left: 85px;
        bottom: -16px;
	}

    @media (max-width: 577px){
        &:before {
            top: -18px;
            left: 215px;
	    }
    }
`

export const TeamTitleWithStarDeveloper = styled(TeamTitle)`
    position: relative;
    &:before {
        content: '';
        position: absolute;
        display: block;
        background: url(${stars});
        width: 41px;
        height: 35px;
        left: 57px;
        top: -18px;
	}

    @media (max-width: 577px){
        &:before {
            top: -18px;
            left: 29px;
	    }
    }
`

export const TeamTitleDesigner = styled(TeamTitle)`
    @media (max-width: 577px){
        position: relative;
        &:before {
            content: '';
            position: absolute;
            display: block;
            background: url(${stars});
            width: 41px;
            height: 35px;
            top: -18px;
            left: 29px;
        }
    }
`