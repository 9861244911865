import React from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'

const Btn = styled.button`
  width: 85px;
  height: 30px;
  background: transparent;
  display: inline-block;
  color: #000;
  outline: none;
  border: 1px solid #949494;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  border-radius: 50px;
  cursor: pointer;
  opacity: 0.8;
  font-size: 14px;
  padding-top: 0px;
  padding-right: 5px;

  span{
    position: relative;
    bottom: 7px;
    right: 7px;
  }

  img{
    width: 20px;
    height: 20px;
    transform: scale(0.8);
    filter: contrast(1);
    margin: 3px 10px;
  }

  @media (max-width: 577px){
    width: 70px;
  }
`


const ButtonFlag = ({text, link}) => {

  const { i18n } = useTranslation()

  function clickLang(lang) {
    i18n.changeLanguage(lang)
  }

  const cookie = Cookies.get('i18next')


  return (
    <>
    {cookie === 'ru' ?
    <Btn onClick={() =>clickLang('en') }>
      <a href={link} aria-label={text} target="_blank" rel="noreferrer">
      
      <img src="https://svgshare.com/i/iBk.svg" alt="flag" />
      <span>
      RU
      </span>
      </a>
    </Btn>
    :
    <Btn onClick={() =>clickLang('ru') }>
    <a href={link} aria-label={text} target="_blank" rel="noreferrer">
    
    <img src="https://svgshare.com/i/n5o.svg" alt="flag" />
    <span>
    EN 
    </span>
    </a>
    </Btn>
    } 
    </>
  )
}

export default ButtonFlag