import { useTranslation } from 'react-i18next'
import {Title, Text, Date, Border} from './../styles';

const New = (props) => {
    const { t } = useTranslation();

    return (
        <>
            <Border/>
            <Title>{props.title}</Title>
            <Text>{props.text}</Text>
            <Date>{props.date}</Date>
        </>
    )
}

export default New;