import { useNavigate, useParams } from "react-router-dom";

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import img from '../../assets/camera.png'
import { useSelector } from 'react-redux'
import axios from '../../utils/axios'
import { selectIsAuth } from '../../redux/slices/auth'
import { Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {DndContext, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors} from '@dnd-kit/core';
import {SortableContext, arrayMove} from '@dnd-kit/sortable';
import ImageWrapper from "./AddWork/Image-Wrapper";
import loadImage from "blueimp-load-image";
import { dataURLtoFile } from "../../utils/base64ToDataUrl";
import CustomRulesTextField from "../../ui-kit/custom-rules-text-field/custom-rules-text-field";
import Toast from "../../ui-kit/toast/toast";
import { Textarea } from "../../ui-kit/custom-rules-text-field/styles";
import { ToastWrapper } from "../../ui-kit/toast/styles";

const Section = styled.section`
width: 610px;
position: relative;
margin-left: 50px;

@media (max-width: 577px){
    margin-left: 0;
    width: auto;
    ${ToastWrapper} {
        margin-left: 1.2rem;
    }
}
`

const Head = styled.div`
width: 610px;
padding-bottom: 10px;
border-bottom: 1px solid rgba(148, 148, 148, 0.5) ;
font-family: 'Palatino';
font-weight: 400;
font-size: 26px;

@media (max-width: 577px){
    width: 380px;
    margin-left: 1.2rem;
    width: auto;
}
`
const AddImg = styled.button`
width: 220px;
height: 270px;
display: flex;
flex-direction: column;
flex-wrap: wrap;
border: 2px dashed rgba(57, 105, 176, 0.33);
border-radius: 20px;
justify-content: center;
align-items: center;
margin-top: 2rem;
font-family: 'Montserrat';
background: rgba(57, 105, 176, 0.13);

@media (max-width: 577px){
    margin: 10px auto 0;
}
`
const ImgCont = styled.div`
img{
    width: 66px;
    height: 63px;
}

`
const TextDesc = styled.div`
text-align: center;
font-size: 11px;
`
const HeadName = styled.div`
font-size: 19px;
font-weight: 500;
color: ${props => props.errorTitle && '#FB0808'};
@media (max-width: 577px){
    margin-left: 1.2rem;
}
`
const FormName = styled.div`
margin-top: 0.5rem;
input {
  width: 562px;
  height: 35px;
  border: 1px solid #949494;
  border-radius: 10px;
  outline: none;
  text-indent: 5px;
}

@media (max-width: 577px){
    input{
    max-width: 360px;
    width: 100%;
    margin-left: 1.2rem;
}
}
`
const Desc = styled.div`
margin-top: 0.5rem;
box-sizing: border-box;
textarea{
  font-family: 'Montserrat';
  width: 562px;
  height: 250px;
  border: 1px solid #949494;
  border-radius: 10px;
  outline: none;
  resize: none;
  padding: 5px 5px;
}

@media (max-width: 577px){
    textarea{
        width: 91%;
        height: 170px;
        margin-left: 1.2rem;
    }
}
`

const HeadDest = styled.div`
    font-size: 19px;
    font-weight: 500;
    color: ${props => props.errorTitle && '#FB0808'} !important;

    @media (max-width: 577px){
        margin-left: 1.2rem;
    }
`
const PriceDest = styled.div`
    width: 282px;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;

    input{
    width: 260px;
    height: 35px;
    border: 1px solid #949494;
    border-radius: 10px;
    outline: none;
    text-indent: 5px;
    margin-top: 10px;
    }

    .second{
        width: 200px;
    }   
    @media (max-width: 577px){
        margin-top: 0;
        width: 150px;
        margin-right: 5px;

        input {
            width: 150px;
            margin-top: 17px;
        }
    }
`

const PriceHead = styled.div`
    font-size: 17px;
    font-weight: 400;
    color: ${props => props.errorTitle && '#FB0808'};

    @media (max-width: 577px){
        white-space: nowrap;
    }
`
const PriceDesc = styled.div`
    margin-top: 10px;
    font-size: 13px;
    font-weight: 400;
    opacity: 0.8;
    height: 32px;
    @media (max-width: 577px){
        font-size: 10px;
    }
`

const InputBlock = styled.div`
display: grid;
grid-template-columns: 315px 230px 35px;

@media (max-width: 577px){
    display: flex;
    margin-left: 1.2rem;
    max-width: 360px;
}
`

const TrashWrapper = styled.div`
  height: 37px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
  
  img {
      width: 20px;
      height: 20px;
      opacity: 0.5;
  }
`

const Trash = styled.div`
    margin-top: 0px;
    img{
        width: 25px;
        height: 25px;
        opacity: 0.5;
    }
`
const Btn = styled.button`
    margin-top: 1rem;
    width: 263px;
    height: 35px;
    background: #F5F5F5;
    border: 1px solid #949494;
    border-radius: 10px;
    font-family: 'Montserrat';

    @media (max-width: 577px){
        margin-left: 1.2rem;
        margin-top: 1.5rem;
    }
`

const Buttn = styled.button`
position: relative;
margin-top: 3rem;
width: 235px;
height: 56px;
background: rgba(254, 97, 3, 0.2);
border: 1px solid rgba(0, 0, 0, 0.2);
border-radius: 10px;
font-family: 'Montserrat';
font-size: 17px;
cursor: pointer;

@media (max-width: 577px){
    margin-left: 1.2rem;
}
`

const FormHead = styled.section`
margin-top: 2rem;

@media (max-width: 577px){
    max-width: 380px;
}
`

const HeadAttributes = styled.div`
font-size: 17px;
font-weight: medium;
`
const TextAttributes = styled.div`
display: flex;
    margin-top: 10px;
    input{
        width: 222px;
        height: 36px;
        border-top: 1px solid #949494;
        border-bottom: 1px solid #949494;
        border-left: 1px solid #949494;
        border-right: none;
        border-radius: 10px 0 0 10px;
        outline: none;
        text-indent: 5px;
        margin-top: 0;
        box-sizing: border-box;
        border-color: #949494;
    }

    select {
        font-family: 'Montserrat';
        height: 36px;
        border-left: none;
        border-radius: 0 10px 10px 0;
        outline: none;
        box-sizing: border-box;
        border-color: #949494;
    }

    .inputSecond, .selectSecond{
    }

    .inputSecond{
        width: 160px;
    }

    .selectSecond{
        margin-left: 0rem;
    }
    @media (max-width: 577px){
        margin-top: 17px;
        input{
            width: 100%;
        }

        .inputSecond{
            width: 100%;
        }
        .selectSecond{
        }
    }
`
const AttributesDropDownFirst = styled.div`
display: flex;

@media (max-width: 577px) {
    flex-direction: column;
    margin-left: 1.2rem;
}
`
const Size = styled.div`
margin-left: 1rem;

@media (max-width: 577px){
    margin-left: 0rem;
    margin-top: 1rem;
}
`
const Price = styled.div`

`
const AttributesDropDownSecond = styled.div`
margin-top: 1rem;
display: flex;

select{

}

@media (max-width: 577px) {
    flex-direction: column;
    margin-left: 1.2rem;
}
`

const DropAttributes = styled.div`
margin-top: 10px;

select{
    font-family: 'Montserrat';
    width: 277px;
    height: 40px;
    border: 1px solid #949494;
    border-radius: 10px;
    outline: none;
    font-size: 17px;
}

.status{
    width: 281px;
}

@media (max-width: 577px){
    select, .status{
        width: 100%;
    }
}
`

const Images = styled.div`
    margin-top: 10px;   
    width: fit-content;
    height: fit-content;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    border-radius: 20px;

    @media (max-width: 577px){
        grid-template-columns: 1fr 1fr;
        margin: 10px auto 0;
        gap: 20px;
    }
`
const HintPrice = styled.div`
position: relative;
margin: 0.5rem 1rem;
font-weight: 500;
font-size: 12px;
opacity: 0.7;

img{
  width: 12px;
  height: 12px;
  opacity: 0.5;
  margin-left: -16px;
  margin-top: 9px;
  position: absolute;
}
`

const FlexBox = styled.div`
@media (max-width: 577px){
    margin-left: 0rem;
    width: 100%;
    display: flex;
    flex-direction: column;
}
`

const InputBox = styled.div`
@media (max-width: 577px){
    width: 100%;
    overflow-x: hidden;
}
`

    const InfoWrapper = styled.div`
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        @media (max-width: 577px){
            max-width: 380px;
            margin-left: 1.2rem;
        }
    `

    const InfoPayment = styled.div`
        width: 100%;

    `

    const InfoDelivery = styled.div`
        width: 100%;

    `

    const InfoLabel = styled.label`
        font-size: 17px;
        font-weight: 400;
        font-family: 'Montserrat';

        ${Textarea} {
        margin-top: 10px;
        width: 100%;
        height: 130px;
        padding: 10px;
        font-size: 14px;

        @media (max-width: 577px){
            max-width: 300px;
        }

        @media (max-width: 350px){
            max-width: 280px;
        }
    }
    `

    const InfoTextarea = styled.textarea`
        margin-top: 10px;
        resize: none;
        width: 100%;
        border: 1px solid #949494;
        border-radius: 10px;
        height: 130px;
        outline: none;
        padding: 10px;
        font-size: 14px;
    `

    const CheckboxContainer = styled.div`
        margin-top: 20px;
        display: flex;
        align-items: center;
        box-sizing: border-box;

        label {
            font-size: 18px;
            cursor: pointer;
            display: flex;
            align-items: center;
        }

        label::before {
            content: "";
            border: 1px solid black;
            height: 30px;
            width: 30px;
            display: block;
            border-radius: 2px;
            margin-right: 8px;
            flex: 0 0 auto;
        }

        input {
            cursor: pointer;
            opacity: 0;
            position: absolute;
        }

        input:checked {
            &+label::before {
                content: '\\002714';
                background-color: #FFE7D9;
                text-align: center;
                border: none;
                font-size: 22px;
                opacity: 0.8;
            }
        }

        @media (max-width: 414px){
            margin-left: 1.2rem;

            label {
                font-size: 13px;
            }

            label::before {
                height: 20px;
                width: 20px;
            }

            input:checked {
                &+label::before {
                    font-size: 16px;
                }
            }
        }
    `

const InfoTitle = styled.div`
font-size: 17px;
line-height: 42px;
font-weight: 500;
color: ${props => props.errorTitle && '#FB0808'};

@media (max-width: 414px){
    font-size: 15px;
}

`
    
const EditWork = () => {
    const { t } = useTranslation()
    const Rules =  [{ regex: /^[a-zA-Z0-9 ’№,.:;?!#$%&()+-_*|'s/(\d{5})/\r\n\1/gs]+$/, errorText: t('OnlyEnglishRule') }]
    const { id } = useParams();
    const isAuth = useSelector(selectIsAuth);
    const inputFileRef = React.useRef(null);
    const [errors, setErrors] = useState({
        destination: (isAuth.delivery[0]?.destination || []).map(() => null)
    });
    const navigate = useNavigate();
    const isDestinationInvalid = useMemo(() => errors.destination.some((err) => Boolean(err)), [errors])
    const isFormInvalid = useMemo(() => Boolean(errors.name) || Boolean(errors.description) || Boolean(errors.deliveryDescription) || Boolean(errors.paymentDescription), [errors]);

    const [cardForm, setCardForm] = useState(null);
    const [clientImages, setClientImages] = React.useState([]);
    
    const [isError, setIsError] = React.useState(false);
    const mouseSensor = useSensor(MouseSensor, {
        activationConstraint: {
          distance: 1,
        },
      })
    const mobileSensor = useSensor(TouchSensor, {
        activationConstraint: {
            distance: 5
        }});
    const keyboardSensor = useSensor(KeyboardSensor)
    const sensors = useSensors(mouseSensor, keyboardSensor, mobileSensor)

    const handleChangeFile = async (event) => { 
        const uploads = await Promise.all([...event.target.files].map((file) => loadImage(
            file,
            { orientation: true, canvas: true, maxWidth: 700, maxHeight: 800 }
        )))

        setClientImages((prev) => ([
            ...prev,
            ...uploads.map(({ image }, index) => ({
                id: `${event.target.files[index].name}_${event.target.files[index].size}_${Math.random()}`,
                name: event.target.files[index].name,
                base64: image.toDataURL(`image/jpeg`)
            }))
        ]))
    }

    const deleteImg = (idForDelete) => {
        setClientImages((prev) => prev.filter(({ id }) => id !== idForDelete))
    }

    const imageRotateHandler = async (imageId, orientation) => {
        const { base64, url } = clientImages.find(({ id }) => id === imageId);


        const nextImage = await loadImage(
            base64 || url,
            { orientation, canvas: true, maxWidth: 700, maxHeight: 800 }
        )

        setClientImages((prev) => prev.map((img) =>
            img.id === imageId
                ? {
                    ...img,
                    base64: nextImage.image.toDataURL(`image/jpeg`)
                }
                : img
        ));
    }

    const handleChangeField = (value, e) => {
        setCardForm((prev) => ({
            ...prev,
            [e.target.name]: value
        }))
        setErrors((prev) => ({
            ...prev,
            [e.target.name]: null
        }));
        setIsError(false)
    }

    const handleChangeSelect = (e) => {
        setCardForm((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
        setErrors((prev) => ({
            ...prev,
            [e.target.name]: null
        }));
        setIsError(false)
    }

    const inputErrorHandler = (err, value, e) => {
        setCardForm(
            (prev) => ({
                ...prev,
                [e.target.name]: value
            })
        );
        setErrors((prev) => ({
            ...prev,
            [e.target.name]: err
        }));
        setIsError(true)
    }

    const handleChangeCheckbox = (e) => {
        setCardForm((prev) => ({
            ...prev,
            [e.target.name]: e.target.checked
        }))
    }

    const handleNumberChangeField = (e) => {
        setCardForm((prev) => ({
            ...prev,
            [e.target.name]: Number(e.target.value)
        }))
    }

    const handleDeliveryFieldChange = (e, index) => {
        setCardForm((prev) => ({
            ...prev,
            delivery: [{
                ...prev.delivery[0],
                [e.target.name]: Object.assign(
                    [],
                    prev.delivery[0][e.target.name],
                    {
                        [index]: e.target?.type === 'number' && e.target.value?.length > 0 
                            ? Number(e.target?.value) 
                            : e.target?.value
                    }
                )
            }]
        }))
        e.target.name === 'destination' && setErrors((prev) => ({
            ...prev,
            destination: prev.destination.map((v, i) => i === index ? null : v)
        }));
        setIsError(false)
    }

    const destinationInputErrorHandler = (err, e, index) => {
        setCardForm((prev) => ({
            ...prev,
            delivery: [{
                ...prev.delivery[0],
                [e.target.name]: Object.assign(
                    [],
                    prev.delivery[0][e.target.name],
                    {
                        [index]: e.target?.type === 'number' && e.target.value?.length > 0 
                            ? Number(e.target?.value) 
                            : e.target?.value
                    }
                )
            }]
        }))
        setErrors((prev) => ({
            ...prev,
            destination: prev.destination.map((v, i) => i === index ? err : v)
        }));
        setIsError(true)
    }

    function handleDragEnd(event) {
        const { active, over } = event;

        if (active && over && active.id !== over.id) {
            setClientImages((items) => {
                const oldIndex = items.findIndex(({ id }) => id === active?.id);
                const newIndex = items.findIndex(({ id }) => id === over?.id);
        
                return arrayMove(items, oldIndex, newIndex);
            });
        }
    }

    const onSubmit = async () => {

        if (isFormInvalid || isDestinationInvalid) return;
        try {
            if (!cardForm.cathegory) {
                return (alert('Категория не указана'))
            }
            // setLoading(true)

            const formData = new FormData();

            clientImages.filter(({ base64 }) => !!base64).forEach(({ base64, name }) => {
                formData.append('images', dataURLtoFile(base64, name));
            })
            const { data } = await axios.post('/upload', formData);
            const imgUrlList = data?.urls || [];

            await axios.patch(`/market/${id}`, {
                ...cardForm,
                ...cardForm.delivery[0],
                imgUrl: clientImages.map(({ url }) => url ? url : imgUrlList.shift()).filter((url) => !!url)
            });
            navigate('/account/CheckWork');

        } catch (err) {
            console.warn(err)
            alert('Ошибка при загрузке товара')
        }
    }

    const handleDeliveryFieldAdd = () => {
        setCardForm((prev) => ({
            ...prev,
            delivery: [{
                destPrice: [...prev.delivery[0].destPrice, ''],
                destCurrency: [...prev.delivery[0].destCurrency, 'USD'],
                destination: [...prev.delivery[0].destination, ''],
            }]
        }))
    };
    
    const handleDeliveryFieldRemove = (index) => {
        setCardForm((prev) => ({
            ...prev,
            delivery: [{
                destPrice: prev.delivery[0].destPrice.filter((_, i) => i !== index),
                destCurrency: prev.delivery[0].destCurrency.filter((_, i) => i !== index),
                destination: prev.delivery[0].destination.filter((_, i) => i !== index),
            }]
        }))
    }

    const fetchCard = useCallback(async () =>{
        const { data } = await axios.get(`/market/${id}`);
        
        setCardForm(data);
    }, [id])
      
    useEffect(() => {
        if (window.localStorage.getItem('token') && !isAuth) {
            return <Navigate to='/' /> 
        }

        fetchCard()
    }, [fetchCard, isAuth])

    useEffect(() => {
        setClientImages(
            cardForm?.imgUrl.map((url) => ({ url, id: url })) || []
        );
    }, [cardForm?.imgUrl])

  return (
    <Section>   
        <Head>
            {t('editWork')}
        </Head>
        <Toast isOpenNotification={isError} isRed  description={t('errorTextAddWork')} withoutClose marginTop/>
        {cardForm && (
            <>
                <AddImg onClick={() => inputFileRef.current.click()}>
                    <ImgCont >
                        <img src={img} alt='camera' />
                    </ImgCont>
                    <TextDesc>
                    {t('mainPhoto2')}
                    </TextDesc>
                </AddImg>
            
                <input
                    ref={inputFileRef}
                    name='images'
                    multiple='multiple'
                    type='file'
                    onChange={handleChangeFile}
                    encType="multipart/form-data"
                    hidden
                />
                <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
                    {clientImages.length === 0 ?
                        <div className='textAdd'>
                            {t('upload')}
                        </div> 
                        :
                        <Images>
                            <SortableContext items={clientImages}>
                                {clientImages.map((image, index) => (
                                    <ImageWrapper
                                        deleteImg={deleteImg}
                                        key={image.id}
                                        id={image.id}
                                        isMain={index === 0}
                                        img={image.base64}
                                        url={`/api${image.url}`}
                                        onImageRotate={imageRotateHandler}
                                    />
                                ))}
                            </SortableContext>
                        </Images> 
                    }
                </DndContext>
            <FlexBox>
                <FormHead>
                <HeadName errorTitle={errors.name}>
                {t('workName')}
                </HeadName>
                <FormName>
                    <CustomRulesTextField marginLeft value={cardForm.name} name="name" onChange={handleChangeField} onError={inputErrorHandler} rules={Rules} />
                    {/* <input type="text" size="2" name="name"  value={cardForm.name} onChange={handleChangeField} /> */}
                </FormName>
                </FormHead>
                <FormHead>
                <AttributesDropDownFirst>
                <Price>
                <HeadAttributes>
                {t('price')}
                </HeadAttributes>
                <TextAttributes>
                    <input type="number" size="2" name="price" value={cardForm.price} onChange={handleNumberChangeField}  />
                    <select value={cardForm.currency} name="currency" onChange={handleChangeSelect}>
                        <option>USD</option>
                        <option>RUB</option>
                        <option>EUR</option>
                    </select>
                
                </TextAttributes>
                </Price>
                <Size>
                <HeadAttributes>
                {t('size')}
                </HeadAttributes>
                <TextAttributes>
                    <input type="number" size="2" name="size" className="second" value={cardForm.size} onChange={handleNumberChangeField} />
                    <select>
                        <option>CM</option>
                        <option>INCH</option>
                    </select>
                </TextAttributes>
                </Size>
                </AttributesDropDownFirst>
                <AttributesDropDownSecond>
                <Price>
                <HeadAttributes>
                {t('cathegory')}
                </HeadAttributes>
                <DropAttributes>
                    <select value={cardForm.cathegory} name="cathegory" onChange={handleChangeSelect}>
                        <option>{t('select')}</option>
                        <option value="teddy">{t('teddy')}</option>
                        <option value="doll">{t('doll')}</option>
                    </select>
                
                </DropAttributes>
                </Price>
                <Size>
                <HeadAttributes>
                {t('status')}
                </HeadAttributes>
                <DropAttributes>
                    <select className="status" value={cardForm.status} name="status" onChange={handleChangeSelect}>
                        <option value={'1'}>{t('forSale')}</option>
                        <option value={'2'}>{t('sold')}</option>
                        <option value={'3'}>{t('reserve')}</option>
                    </select>
                </DropAttributes>
                </Size>
                </AttributesDropDownSecond>
                </FormHead>
                <FormHead>
                <HeadName errorTitle={errors.description}>
                {t('description')}
                </HeadName>
                <Desc>
                    <CustomRulesTextField marginLeft isTextarea value={cardForm.description} name="description" onChange={handleChangeField} onError={inputErrorHandler} rules={Rules}/>
                    {/* <textarea name="description" value={cardForm.description} onChange={handleChangeField}></textarea> */}
                </Desc>
                </FormHead>
            </FlexBox>
            <InputBox>
            <FormHead>
            <HeadDest errorTitle={errors.destination.some((item) => Boolean(item))}>
                {t('destination')}
                <HintPrice><img src='https://svgshare.com/i/jcz.svg'  alt="destination" /> {t('tooltip1')} <br/> {t('tooltip2')}</HintPrice>
            </HeadDest>
            
            <InputBlock>
            <PriceDest>
                <PriceHead errorTitle={errors.destination.some((item) => Boolean(item))}>
                {t('addCountry')}
                </PriceHead>
                <PriceDesc>
                {t('destTooltip')}
                </PriceDesc>
                {cardForm.delivery[0].destination.map((c, index) => (
                    <CustomRulesTextField 
                        key={index} 
                        value={c}
                        name="destination"
                        onChange={(_, e) => handleDeliveryFieldChange(e, index)}
                        onError={(err, _, e) => destinationInputErrorHandler(err, e, index)}
                        rules={[{ regex: /^[a-zA-Z0-9 ]+$/}]}
                    />
                    // <input
                    //     type="text"
                    //     size="2"
                    //     key={index}
                    //     value={c}
                    //     name="destination"
                    //     onChange={(e) => handleDeliveryFieldChange(e, index)}
                    // />
                ))}
            </PriceDest>
            
            <PriceDest>
                <PriceHead>
                {t('destPrice')}
                </PriceHead>
                <PriceDesc>
                {t('destPriceTooltip')}
                </PriceDesc>
                {cardForm.delivery[0].destPrice.map((price, index) => (
                    <TextAttributes>
                        <input
                            value={price}
                            className='inputSecond'
                            type="number"
                            size="2"
                            name="destPrice"
                            onChange={(e) => handleDeliveryFieldChange(e, index)}
                        />
                        <select
                            value={cardForm.delivery[0].destCurrency[index]}
                            className='selectSecond'
                            name="destCurrency"
                            onChange={(e) => handleDeliveryFieldChange(e, index)}
                        >
                            <option>USD</option>
                            <option>RUB</option>
                            <option>EUR</option>
                        </select>
                        <Trash>
                            <TrashWrapper>
                                <img 
                                    src="https://svgshare.com/i/iEC.svg" 
                                    onClick={() => handleDeliveryFieldRemove(index)} 
                                    alt="trash" 
                                />
                            </TrashWrapper>
                        </Trash>
                    </TextAttributes>
                ))}
            </PriceDest>
            </InputBlock>
            <Btn onClick={handleDeliveryFieldAdd}>
                {t('addCountry')}
            </Btn>
            <InfoWrapper>
                <InfoPayment>
                    <InfoLabel>
                        <InfoTitle errorTitle={errors.paymentDescription}>{t('paymentInfo')}</InfoTitle>
                        <CustomRulesTextField isTextarea value={cardForm.paymentDescription} name="paymentDescription" onChange={handleChangeField} onError={inputErrorHandler} rules={Rules}/>
                    </InfoLabel>
                </InfoPayment>
                <InfoDelivery>
                    <InfoLabel>
                        <InfoTitle errorTitle={errors.deliveryDescription}>{t('deliveryInfo')}</InfoTitle>
                        <CustomRulesTextField isTextarea value={cardForm.deliveryDescription} name="deliveryDescription" onChange={handleChangeField} onError={inputErrorHandler} rules={Rules}/>
                    </InfoLabel>
                </InfoDelivery>
            </InfoWrapper>
            {isAuth.isMaster &&
                <CheckboxContainer>
                    <input id='online-show' name='showpageDisplayed' checked={cardForm.showpageDisplayed} onChange={handleChangeCheckbox} type='checkbox'/>
                    <label for='online-show'>{t('showParticipantWork')}</label>
                </CheckboxContainer>
            }        
            </FormHead>
            
            </InputBox>
            <Buttn onClick={onSubmit}>
            {t('place')}
            </Buttn>
            </>
        )}
    </Section>
  )
}

export default EditWork;