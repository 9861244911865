import React, { useMemo } from 'react'
import styled from 'styled-components/macro'
import OrderMenu from './OrderMenu'
import { useEffect, useState } from 'react'
import axios from '../../utils/axios'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import defaultWork from './../../assets/defaultToy.png'

const Section = styled.section`

@media (max-width: 577px){
    width: 100%;
}
`

const Block = styled.section`
border-bottom: 1px solid rgba(148, 148, 148, 0.2);
top: 0rem;
left: 0.1rem;
width: 790px;
display: flex;
@media (max-width: 577px){
    width: 90%;
    margin-left: 1.2rem;
    display: block;
}
`

const TableHead = styled.ul`
width: 790px;
display: grid;
grid-template-columns: 300px 490px;
list-style: none;
border-bottom:  1px solid rgba(171, 154, 144, 0.2);
font-weight: 600;
padding: 0.5rem 0;
margin-top: 1rem;

@media (max-width: 577px){
    width: 90%;
    margin-left: 1.2rem;
    .works{
        display: none;
    }
}
`
const TablePm = styled.li`
opacity: 0.85;

`

const Prof = styled.div`
    height: 80px;
    width: 311px;
    display: flex;
    padding: 10px 0;
`

const ProfAvatar = styled.div`
width: 65px;
height: 65px;
border: 1px solid rgba(148, 148, 148, 0.7);
border-radius: 50px;
margin: 0.6rem;
background: #F5F5F5;

img{
    width: 65px;
    height: 65px;
    border-radius: 50px;
    object-fit: cover;
}
`
const ProfName = styled.h4`
font-size: 9.5pt;      
    margin-bottom: 3px;
    margin-top: 10px;   
`
const Short = styled.h5`
font-size: 12px;
    font-weight: 500;
    opacity: 0.8;
    margin-bottom: 3px;
`
const ButtonBlock = styled.div`
   display: flex;
    margin-top: 3px;
    margin-left: -5px;
`
const BtnDesub = styled.button`
position: absolute;
width: 100px;
height: 30px;
background-color: #F5F5F5;
color:  #000;
outline: none;
border: 0px solid #808080;
font-family: Montserrat;
font-weight: 400;
font-size: 12px;
border-radius: 10px;
cursor: pointer;
margin-left: 0rem;

`
const BtnBell = styled.button`
    position: absolute;
    width: 40px;
    height: 30px;
    color:  #000;
    outline: none;
    border: 0px solid #808080;
    font-family: Montserrat;
    font-weight: 400;
    font-size: 12px;
    border-radius: 10px;
    cursor: pointer;
    margin-left: 7rem;

    img{
        height: 15px;
        width: 15px;
    }

    .bell {
        background-color: #F5F5F5;
        transition: background-position 1s steps(28);
        transition-duration: 0s;

        &.is-active {
            align-items: center;
            justify-content: center;
            display: flex;
            width: 40px;
            height: 30px;
            border-radius: 10px;
            background-color: #D5C8C8;
            transition-duration: 1s;
        }
    }
`
const Works = styled.div`
display: flex;
align-items: center;
    img{
        height: 80px;
        width: 75px;
        object-fit: cover;
        margin-left: 1rem;
        border-radius: 10px;
    }

    @media (max-width: 577px){
        margin-bottom: 10px;

        img{
            height: 60px;
            width: 60px;
            margin-left: 10px;
        }
    }
`

const ImgBlock = styled.div`
background-color: #f1f1f1;
border-radius: 10px;
height: 80px;
width: 75px;
margin-left: 1rem;


`

const Head = styled.div`
width: 840px;
font-weight: 400;
font-size: 26px;
display: flex;
margin-left: 0.1rem;
margin-top: -2rem;
padding-bottom: 10px;
border-bottom: 1px solid rgba(148, 148, 148, 0.5) ;
font-family: 'Palatino';

img{
    width: 20px;
    position: relative;
    opacity: 0.8;
    top: 2px;
    left: 10px;
}
`
const ProfInfo = styled.div`
    display: flex;
    flex-direction: column;
`

const Subs = () => {
    const [subs, setSubs] = useState([])  
    const { t } = useTranslation();

    const fetchMySubs = async () => {
      try{
          const { data } = await axios.get(`users/me/subscribed`)
          setSubs(data)
      } catch (error) {
          console.log(error)
      }
    }

    const handleClick = (e, id) => {
        e.preventDefault();
        e.stopPropagation();
        axios.delete(`/users/subscribe/${id}`).then(
            () => {setSubs((prev) => 
                prev.filter((sub) => 
                    sub._id !== id
                )    
            )}
        )
    }

    const subscribeMail = (id) => {
        
        axios.patch(`/users/subscribe/${id}/mail`).then(
            setSubs(
                (prevValue) => prevValue.map((sub) => ({
                    ...sub,
                    enableEmail: sub._id === id ? true : sub.enableEmail
                }))
            )
        )
    }

    const unSubscribeMail = (id) => {
        axios.delete(`/users/subscribe/${id}/mail`).then(
            setSubs(
                (prevValue) => prevValue.map((sub) => ({
                    ...sub,
                    enableEmail: sub._id === id ? false : sub.enableEmail
                }))
            )
        )
    }


    useEffect(() => {
      fetchMySubs();
    }, [])
  
    if(!subs){
      return(
          <div>Загрузка</div>
      )
    }

  return (
    <div>
    <OrderMenu />
    <Section>
    <TableHead>
            <TablePm>{t('nameTab')}</TablePm>
            <TablePm className='works'>{t('works')}</TablePm>
    </TableHead>
    {subs?.map((sub, index) =>(
        <Block>
            <div>
                <Link to={`/${sub.shortlink || sub._id}`}>
                    <Prof>
                        <ProfAvatar>
                            <img src={`https://toobears.com/api/${sub.avatarUrl}`} alt={sub.username} />
                        </ProfAvatar>
                        <ProfInfo>
                            <ProfName>{sub.username} {sub.surname}</ProfName>
                            <Short>{sub.shopname}</Short>
                            <ButtonBlock>
                                <BtnDesub onClick={(e) => handleClick(e, sub._id)}>
                                    <span>{t('unsub')}</span>
                                </BtnDesub>
                                <BtnBell  onClick={(e) => {
                                    const activeSub = subs.find(({_id}) => _id === sub._id);
                                    activeSub.enableEmail ? unSubscribeMail(sub._id) : subscribeMail(sub._id)
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}>
                                    <div className={sub.enableEmail ? 'bell is-active' : 'bell'}>
                                        <img src="https://svgshare.com/i/iCa.svg" alt='bell' />
                                    </div>
                                </BtnBell>
                            </ButtonBlock>
                        </ProfInfo>
                    </Prof>
                </Link>
            </div>
            <Works>
                {sub.cards?.slice(0, 4).map((card, index) => (
                    <Link to={`/card/${card._id}`}><img src={card.imgUrl ? `https://toobears.com/api/${card.imgUrl[0]}` : defaultWork} alt={card.name} /></Link>
                ))}
            </Works>
        </Block>
    ))}
    </Section>
    </div>
  )
}

export default Subs